import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
export function PolarChart({ data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);

  const labels = filteredData?.cropsMarketingCrops
    ? filteredData.cropsMarketingCrops.map((item) => item.name)
    : [];
  const polarData = {
    labels: labels,
    datasets: [
      {
        label: 'Quantity of crop received in marketing',
        data: filteredData?.cropsMarketingCrops
          ? filteredData.cropsMarketingCrops.map((item) => item.totalQuantity)
          : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return <PolarArea data={polarData} />;
}

import React from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import moment from "moment";
import { BiDetail } from "react-icons/bi";

const ViewModel = ({ setOpenViewModel, selectedItem }) => {
    return (
        <Model width={"w-11/12 max-w-2xl"} setOpenModel={setOpenViewModel}>
            <Wrapper>
                <Title>
                    {" "}
                    <span className="flex flex-row">
                        <BiDetail className="mt-1 mr-2" />
                        View Details
                    </span>
                </Title>
                {/* <SingleItem name={"Report Name"} value={selectedItem?.name} /> */}
                <SingleItem
                    name={"Date Range"}
                    value={
                        moment(selectedItem.start_date).format("DD/MM/YYYY") +
                        " - " +
                        moment(selectedItem.end_date).format("DD/MM/YYYY")
                    }
                />

                <SingleItem
                    name={"Creation Date"}
                    value={moment(selectedItem?.created_at).format("DD-MM-YYYY hh:mm A")}
                />
                {selectedItem?.status == 2 && (
                    <SingleItem
                        name={"Generated Date"}
                        value={moment(selectedItem?.updated_at).format(
                            "DD-MM-YYYY hh:mm A"
                        )}
                    />
                )}
            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value, link, url }) => (
    <div className="flex items-center py-2 border-b border-gray-100 ">
        <Name>{name}</Name> <p className="w-10">:</p>
        {link && (
            <LinkValue href={url} target="_blank" title="Click to open">
                {value}
            </LinkValue>
        )}
        {!link && <Value>{value}</Value>}
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`text-sm text-gray-800 font-medium max-w-xs `;
const LinkValue = tw.a`text-sm text-blue-500 max-w-xs truncate cursor-pointer`;

export default ViewModel;

import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import CustomPhoneInput from "../CountryCode";
const EditSample = ({
    EditSampleLoading,
    editSampleUser,
    setEditSample,
    selectedData,
}) => {

    const InitialValues = {
        id: selectedData?.id,
        collectorName: selectedData?.sampleCollector,
        count: selectedData?.sampleCount,
        description: selectedData?.sampleDescription,
    };

    const SubmitHandler = (values) => editSampleUser(values);

    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditSample}>
            <Title>Edit Sample</Title>

            {EditSampleLoading && <Loading />}

            {!EditSampleLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>
                            <CombineInputGroup>
                                <InputGroup>
                                    <Label htmlFor="collectorName">Sample Collector Name</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="collectorName"
                                            id="collectorName"
                                            placeholder="Collector Name"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>

                                <InputGroup>
                                    <Label htmlFor="count">Sample Count</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="number"
                                            name="count"
                                            id="count"
                                            placeholder="Count"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>

                            </CombineInputGroup>
                            <InputGroup>
                                <Label htmlFor="description">
                                    Description
                                </Label>
                                <FieldWrapper>
                                    <Field
                                        component="textarea"
                                        row="10"
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        placeholder={
                                            "Container (Id:1234E85) : 1 Sample\n" +
                                            "Container (Id:4586G86) : 1 Sample"}
                                        autoComplete="off"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>


                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Update</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditSample;

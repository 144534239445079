import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import CustomPhoneInput from "../CountryCode";
import { toast } from "react-toastify";
const EditSubAdminModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const InitialValues = {
    name: selectedData?.name,
    status: selectedData?.status,
    type: selectedData?.userType,
    email: selectedData?.email,
    mobile: selectedData?.mobile,
    id: selectedData?.id,
  };

  const SubmitHandler = (values) => {
    const isMobileValid = /^[0-9]{9,15}$/.test(values.mobile);

    if (!isMobileValid) {
      toast.error("Mobile number must be between 9 and 15 digits.");
      return;
    }
    editAddUser(values);
  }

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit User</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="name" >Name</Label>
                  <FieldWrapper>
                    <Field name="name" id="name" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                {/* <InputGroup>
                  <Label htmlFor="email">Email</Label>
                  <FieldWrapper>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      className="truncate"
                      required
                      disabled
                    />
                  </FieldWrapper>
                </InputGroup> */}
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="mobile">Mobile Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="mobile"
                      id="mobile"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="status" id="status" autoComplete="off">
                      {(props) => (
                        <Select
                          className="w-full h-full pl-2"
                          {...props.field}
                        >
                          <MenuItem value="1">Inactive</MenuItem>
                          <MenuItem value="2">Active</MenuItem>

                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="type">Type</Label>
                <FieldWrapper $select={true}>
                  <Field required name="type" id="type" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        {...props.field}
                      >
                        <MenuItem value="1">Document Collector</MenuItem>
                        <MenuItem value="2">Sample Collector</MenuItem>
                        <MenuItem value="3">Quality Assurer</MenuItem>
                        <MenuItem value="4">Inspector</MenuItem>
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditSubAdminModel;

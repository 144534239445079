import React, { useState, useEffect, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import Loading from "../../Components/Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Underline,
  BoxContainer,
  Page,
} from "../../Components/Styles/PageStyles";
import Select from "react-select";
import Navbar from "../../Components/Navbar";
import moment from "moment";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const AddSample = ({}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { id, blNumber } = useParams();
  // const currentDate = moment().format("YYYY-MM-DD");
  const [rows, setRows] = useState([
    {
      containerNo: "",
      // routes: "",
      // prefrence: "",
      // samplingTime: "",
      samplingTime: moment().format("YYYY-MM-DDTHH:mm"),
      samplingCondition: "",
      productInspected: "",
      isSizeAdequate: "",
      samplingLocation: "",
      specialMark: "",
      count: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        containerNo: "",
        // routes: "",
        // prefrence: "",
        // samplingTime: "",
        samplingTime: moment().format("YYYY-MM-DDTHH:mm"),
        samplingCondition: "",
        productInspected: "",
        isSizeAdequate: "",
        samplingLocation: "",
        specialMark: "",
        count: "",
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();
      setRows(updatedRows);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedRows = [...rows];
    updatedRows[index][fieldName] = value;

    setRows(updatedRows);
  };

  const user = useSelector((state) => state.UserReducer.user);

  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [isAddMoreAvailable, setIsAddMoreAvailable] = useState(true);
  const [error, setError] = useState(null);
  const EditProduct = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/get-containers/${id}/${blNumber}`,
        {
          headers: {
            Authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data,
    isLoading: queryLoading,
    error: queryError,
  } = useQuery("get-production", EditProduct, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error);
      setProductionLoading(false);
    },
  });

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);

  const InitialValues = {
    samplingTime: moment().format("YYYY-MM-DD"),
  };

  const AddGenerateFunction = async (values) => {
    const body = new FormData();

    const containers = values.containers.map((container) => ({
      ...container,
      containerNo: container.containerNo
        .map((number) => number.value)
        .join(","),
    }));
    // console.log(containers);
    // body.append("submitBy", values.submitBy);
    // body.append("serialNumber", values.serialNumber);
    body.append("containers", JSON.stringify(containers) || "");
    body.append("BillNumber", values.billNumber);
    body.append("billLeading", blNumber);
    body.append("id", id);

    return await axios.post(`${Config.apiUrl}/add-sample`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");
      if (res?.data?.certificate) {
        const certificateUrl = res.data.certificate;
        window.open(certificateUrl, "_blank");
      }
      navigate("/client-list");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: generateCerificate } = useMutation(
    AddGenerateFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    const updatedValues = {
      ...values,
      containers: rows,
    };

    generateCerificate(updatedValues);
  };

  const adequite = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const containerList = data?.data?.details?.map((item) => ({
    value: item.containerNo,
    label: item.containerNo,
  }));

  const getContainerList = () => {
    try {
      const previousSelectedContainers = rows.reduce((total, curRow) => {
        const containerFromCurRow = curRow.containerNo;
        total = [...total, ...containerFromCurRow];
        return total;
      }, []);

      const filteredArr = containerList.reduce((AccfilteredArr, curObj) => {
        const isSelected = previousSelectedContainers.find(
          (container) => container.value === curObj.value
        );
        if (!isSelected) {
          AccfilteredArr.push(curObj);
        }
        if (AccfilteredArr?.length === 0) {
          setIsAddMoreAvailable(false);
        } else {
          setIsAddMoreAvailable(true);
        }

        return AccfilteredArr;
      }, []);

      return filteredArr;
    } catch (error) {
      console.log("Error while getting contianer list- ", error);
      return [];
    }
  };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          {productionLoading && <Loading />}

          {!productionLoading && (
            <Box>
              <div className="flex w-full justify-center items-center">
                <div>
                  <Title>Add Sample</Title>
                  <Underline />
                </div>
              </div>

              {/* <TextWrapper> */}
              <Formik
                initialValues={InitialValues}
                onSubmit={SubmitHandler}
                enableReinitialize
              >
                {({ values: formikValues }) => {
                  return (
                    <>
                      <Form>
                        <Wrapper>
                          <InputGroup>
                            <Label htmlFor="billNumber">Bill Number</Label>
                            <FieldWrapper>
                              <Field
                                type="text"
                                name="billNumber"
                                id="billNumber"
                                // placeholder="Bill Number"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          {rows.map((row, index) => (
                            <>
                              <div
                                className="border border-gray-100 px-3 pb-6 pt-2  bg-gray-50 rounded-md "
                                // key={index}
                              >
                                <InputGroup className="mb-4">
                                  <Label htmlFor={`containerNo${index}`}>
                                    Container Number
                                  </Label>
                                  <FieldWrapper
                                    $select={true}
                                    className="!h-auto"
                                  >
                                    <Field
                                      required
                                      name={`containerNo${index}`}
                                      id={`containerNo${index}`}
                                      autoComplete="off"
                                    >
                                      {(props) => {
                                        const customContainerList =
                                          getContainerList();
                                        return (
                                          <Select
                                            className="w-full"
                                            required
                                            options={customContainerList}
                                            isMulti
                                            classNamePrefix="select"
                                            value={row.containerNo}
                                            onChange={(selectedOption) =>
                                              handleInputChange(
                                                index,
                                                "containerNo",
                                                selectedOption
                                                  ? selectedOption
                                                  : [{ label: "", value: "" }]
                                              )
                                            }
                                          />
                                        );
                                      }}
                                    </Field>
                                  </FieldWrapper>
                                </InputGroup>

                                <div
                                  className="grid space-y-2 mt-3  "
                                  key={index}
                                >
                                  {/*  */}

                                  {/* <div className="grid md:grid-cols-2 gap-4 ">
                                    <InputGroup>
                                      <Label htmlFor={`routes${index}`}>
                                        Routes
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="text"
                                          name={`routes${index}`}
                                          id={`routes${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.routes}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "routes",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                    <InputGroup>
                                      <Label htmlFor={`prefrence${index}`}>
                                        Standard Prefrence
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="text"
                                          name={`prefrence${index}`}
                                          id={`prefrence${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.prefrence}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "prefrence",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                  </div> */}

                                  {/*  */}
                                  <div className="grid md:grid-cols-3 gap-4 ">
                                    <InputGroup>
                                      <Label htmlFor={`samplingTime${index}`}>
                                        Time Sampled/Submitted
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="datetime-local"
                                          name={`samplingTime${index}`}
                                          id={`samplingTime${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.samplingTime}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "samplingTime",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                    <InputGroup>
                                      <Label
                                        htmlFor={`samplingCondition${index}`}
                                      >
                                        Sample(s) Condition/Temp
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="text"
                                          name={`samplingCondition${index}`}
                                          id={`samplingCondition${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.samplingCondition}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "samplingCondition",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                    <InputGroup>
                                      <Label
                                        htmlFor={`productInspected${index}`}
                                      >
                                        Product Inspected
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="text"
                                          name={`productInspected${index}`}
                                          id={`productInspected${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.productInspected}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "productInspected",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                  </div>
                                  <div className="grid md:grid-cols-3 gap-4">
                                    <InputGroup>
                                      <Label htmlFor={`isSizeAdequate${index}`}>
                                        Sample Size: Adequate?
                                      </Label>
                                      <FieldWrapper $select={true}>
                                        <Field
                                          type="text"
                                          name={`isSizeAdequate${index}`}
                                          id={`isSizeAdequate${index}`}
                                          autoComplete="off"
                                        >
                                          {(props) => (
                                            <Select
                                              className="w-full h-full"
                                              // required
                                              options={adequite}
                                              classNamePrefix="select"
                                              value={adequite.find(
                                                (option) =>
                                                  option.value ===
                                                  row.isSizeAdequate
                                              )}
                                              onChange={(selectedOption) =>
                                                handleInputChange(
                                                  index,
                                                  "isSizeAdequate",
                                                  selectedOption.value
                                                )
                                              }
                                            />
                                          )}
                                        </Field>
                                      </FieldWrapper>
                                    </InputGroup>

                                    <InputGroup>
                                      <Label htmlFor={`count${index}`}>
                                        No. of samples
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="number"
                                          name={`count${index}`}
                                          id={`count${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.count}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "count",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                    <InputGroup>
                                      <Label
                                        htmlFor={`samplingLocation${index}`}
                                      >
                                        Sampling Location
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          type="text"
                                          name={`samplingLocation${index}`}
                                          id={`samplingLocation${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.samplingLocation}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "samplingLocation",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                  </div>
                                  <div className="grid md:grid-cols-3 gap-4">
                                    <InputGroup className="col-span-3">
                                      <Label htmlFor={`specialMark${index}`}>
                                        Special Marks Required
                                      </Label>
                                      <FieldWrapper>
                                        <Field
                                          component="textarea"
                                          row="10"
                                          type="textarea"
                                          name={`specialMark${index}`}
                                          id={`specialMark${index}`}
                                          autoComplete="off"
                                          className="truncate"
                                          // required
                                          value={row.specialMark}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "specialMark",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FieldWrapper>
                                    </InputGroup>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                          <div className="flex -mt-5">
                            {isAddMoreAvailable && (
                              <button
                                type="button"
                                onClick={addRow}
                                className="text-blue-500 text-sm mr-4"
                              >
                                Add Another Sample
                              </button>
                            )}
                            {rows.length > 1 && (
                              <button
                                type="button"
                                onClick={() => removeRow()}
                                className="text-blue-500 text-sm "
                              >
                                Remove Sample
                              </button>
                            )}
                          </div>

                          <p className="text-sm text-cyan-500  ">
                            IMPORTANT NOTICE!!! BELOW ARE MINIMUM SAMPLE SIZES
                            REQUIRED FOR LABORATORY ANALYSIS. SMALLER SAMPLE
                            SIZE MAY DELAY ANALYSIS PROCESS.
                          </p>
                          <p className="text-xs text-gray-500 -mt-5 ">
                            (1) Petroleum products – Diesel/Kerosine/Lubes-1
                            litre; Gasoline -2 litres, JetA1-5 litres, Fuel Oil
                            -2litres (2) Water/Waste Water - 2X1litre (in
                            duplicate) (3) Food Products - 2X500g (in duplicate)
                            (4) Water (200mL) (5) Other Products - 2X250g (in
                            duplicate)
                          </p>
                        </Wrapper>
                        <BtnWrapper>
                          <SubmitBtn type="submit" disabled={isLoadingReg}>
                            {" "}
                            {isLoadingReg ? (
                              <Loading
                                color="white"
                                width={20}
                                height={20}
                                noPadding={true}
                              />
                            ) : (
                              t("Add")
                            )}
                          </SubmitBtn>
                        </BtnWrapper>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </Box>
          )}
        </BoxContainer>
      </Page>
    </>
  );
};

const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
  grid  gap-6 my-6 px-1`;

export default AddSample;

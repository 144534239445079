import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import NotFoundModel from "../../Components/NotFoundModel";
import Table from "../../Components/Upload/Table";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputGridGroup,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
  FieldCustomWrapperDoc,
  Page,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import DropZone from "../../Components/DropzoneExcel";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
const DocumentUpload = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedData, setSelectedData] = useState("");

  const fetchFunction = async () => {
    const body = new FormData();

    return await axios.post(`${Config.apiUrl}/get-upload-list`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getClientListSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getClientListError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getClientListMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getClientListSuccess,
    onError: getClientListError,
  });

  useEffect(() => {
    getClientListMutate();
  }, []);

  // const submitHandler = (values) => {

  //   getClientListMutate();
  // };

  //------- Add Single Entry -------
  const AddUserFunction = async (values) => {
    const body = new FormData();
    body.append("file", values.document);
    body.append("type", values.type);
    return await axios.post(`${Config.apiUrl}/upload-document`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");

      navigate("/client-list");
    } else {
      toast.error(data?.data?.msg);
    }
  };

  const onAddUserError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postUser } = useMutation(
    AddUserFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  // const SubmitHandler = (values) => {
  //   postUser({ ...values });
  // };
  const SubmitHandler = (values) => {
    if (!values.document) {
      toast.error("Please upload document ");
      return;
    }

    postUser({ ...values });
  };

  const InitialValue = {
    document: "",
  };
  return (
    <>
      {/* <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      > */}
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>Upload Discharging List</Title>
                <Underline />
              </div>
            </div>
            {/* <TextWrapper> */}
            <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
              <>
                <Form>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="type">Type</Label>
                      <FieldWrapper $select={true}>
                        <Field
                          required
                          name="type"
                          id="type"
                          autoComplete="off"
                        >
                          {(props) => (
                            <Select
                              className="w-full h-full pl-2"
                              {...props.field}
                            >
                              <MenuItem value="1">CMA LIST</MenuItem>
                              <MenuItem value="2">MSC LIST</MenuItem>
                              <MenuItem value="3"> MAERSK LIST</MenuItem>
                            </Select>
                          )}
                        </Field>
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="document">Document</Label>
                      <FieldCustomWrapperDoc $select={true}>
                        <Field
                          name="document"
                          type="text"
                          id="document"
                          autoComplete="off"
                          required
                        >
                          {(props) => (
                            <DropZone
                              fields={props.field}
                              setFieldValue={props.form.setFieldValue}
                              componentFor="document"
                              acceptFiles="application/pdf"
                              File={
                                props.field.value ? [props.field.value] : null
                              }
                            />
                          )}
                        </Field>
                      </FieldCustomWrapperDoc>
                      <p className="text-xs text-gray-500 mt-2 ">
                        Max size: 10MB
                      </p>
                    </InputGroup>
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={AddSubAgentLoading}>
                      {" "}
                      {AddSubAgentLoading ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Upload")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              </>
            </Formik>

            {/* </TextWrapper> */}
            <div className="mt-8">
              <Title>Recent Discharging List</Title>
              <Underline />
            </div>
            {!error && !isLoading && data && (
              <TableWrapper>
                <Table
                  ApiData={error ? [] : data?.data?.list}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              </TableWrapper>
            )}

            {(isLoading || isRefetching) && <Loading />}
            {(error || data?.data?.data?.list == 0) && !isLoading && (
              <NotFoundModel />
            )}
          </Box>
        </BoxContainer>
      </Page>
      {/* </Bg> */}
    </>
  );
};

const TextWrapper = tw.div`h-full w-full  flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const TableWrapper = tw.h1`my-10 border bg-white   rounded-md overflow-auto`;
export default DocumentUpload;

import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import CustomPhoneInput from "../CountryCode";
const EditWarehouseModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const InitialValues = {
    name: selectedData?.name,
    email: selectedData?.email,
    phone: selectedData?.phone,
    status: selectedData?.status,
    id: selectedData?.id,
    role: selectedData?.role,

  };

  const SubmitHandler = (values) => editAddUser(values);

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Warehouse Manager</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="phone">Phone Number</Label>
                  <Field
                    name="phone"
                    render={({ field, form }) => (
                      <CustomPhoneInput field={field} form={form} />
                    )}
                  />
                  {/* <FieldWrapper>
                    <Field
                      type="number"
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper> */}
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="status" id="status" autoComplete="off">
                      {(props) => (
                        <Select
                          className="w-full h-full pl-2"
                          {...props.field}
                        >
                          <MenuItem value="2">Active</MenuItem>
                          <MenuItem value="1">Inactive</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="truncate"
                  // disabled
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="role">Role</Label>
                <FieldWrapper $select={true}>
                  <Field required name="role" id="role" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        {...props.field}
                      >
                        <MenuItem value="1">Add</MenuItem>
                        <MenuItem value="2">Edit</MenuItem>
                        <MenuItem value="3">Both</MenuItem>
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              <Field type="hidden" name="id" value={selectedData?.id || ""} />

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditWarehouseModel;

import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function RadarChart({ data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);

  const labels = filteredData?.totalStockCrops
    ? filteredData.totalStockCrops.map((item) => item.name)
    : [];
  const radarData = {
    labels: labels,
    datasets: [
      {
        label: 'Balance of each crop in kg',
        data: filteredData?.totalStockCrops
          ? filteredData.totalStockCrops.map((item) => item.balanceQuantity)
          : [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };


  return <Radar data={radarData} />;
}

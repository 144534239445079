import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import Loading from "../../Components/Loading";
import { IoIosTimer } from "react-icons/io";
import {
  InputGroup,
  FieldWrapper,
  CombineInputGridGroup,
  CombineInputThreeGroup,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import {
  Underline,
  BoxContainer,
  Page,
  FieldCustomWrapperDoc,

} from "../../Components/Styles/PageStyles";
import moment from "moment";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { IoIosCheckmark } from "react-icons/io";
import { FcApproval } from "react-icons/fc";
import { FaFileDownload, FaPlus, FaFileExport } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import DropZone from "../../Components/DropZonePdf";
import { MdUploadFile } from "react-icons/md";
import LabRemarkCertificate from "../../Components/SampleCollectorUser/LabRemarkCertificate";
const UpdateSampleDetails = () => {
  const { t, i18n } = useTranslation();
  // const [showRejectionCause, setShowRejectionCause] = useState(false);
  const [rows, setRows] = useState([
    {
      containerNo: "",
      routes: "",
      prefrence: "",
      // labRemarks:"",
      samplingTime: "",
      samplingCondition: "",
      productInspected: "",
      isSizeAdequate: "",
      samplingLocation: "",
      specialMark: "",
      count: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        routes: "",
        prefrence: "",
      },
    ]);
  };

  const [showRejectionCause, setShowRejectionCause] = useState(
    Array(rows.length).fill(false)
  );

  const navigate = useNavigate();
  const { id, blNumber } = useParams();

  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState("");
  const [editUser, setEditUser] = useState(false);
  const [showLabRemarkModal, setShowLabRemarkModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectionContainerIndex, setRejectionContainerIndex] = useState(null);

  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fieldValue, setFieldValue] = useState([]);

  const EditProduct = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/get-details/${id}/${blNumber}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data,
    refetch,
    isLoading: queryLoading,
    error: queryError,
    isRefetching,
  } = useQuery("get-production", EditProduct, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error);
      setProductionLoading(false);
    },
  });

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);

  const InitialValues = {
    serialNumber: data?.data?.sample?.serialNumber,
    submitby: data?.data?.sample?.submittedBy,
    BillNumber: data?.data?.sample?.billNumber,
    billLeading: data?.data?.sample?.blNumber,
  };

  const mapSubmittedBy = (value) => {
    if (value === "1") {
      return "Client";
    } else if (value === "2") {
      return "Staff";
    } else {
      return "";
    }
  };

  const mapIsSizeAdequate = (value) => {
    if (value === "1") {
      return "Yes";
    } else if (value === "2") {
      return "No";
    } else {
      return "";
    }
  };

  const containerList = data?.data?.sampleItems?.map((item) => ({
    // routes: item.routes,
    // prefrence: item.prefrence,
    containerNo: item.containerNumber,
    samplingTime: item.samplingTime,
    samplingLocation: item.samplingLocation,
    samplingCondition: item.sampleCondition,
    specialMark: item.specialMarksRequired,
    count: item.count,
    productInspected: item.productInspected,
    isSizeAdequate: item.isSizeAdequate,
    sampleItemId: item?.id,
    status: item.status,
    routes: item.routes || "",
    prefrence: item.prefrence || "",
    certificate: item.certificate,
    labResult: item.labResult || "",
    action: "",
    rejectionCause: "",
  }));
  // console.log(containerList)

  const handleInputChange = (index, fieldName, value) => {
    const container = fieldValue?.[index] || {};
    container[fieldName] = value;
    fieldValue[index] = container;

    setFieldValue(fieldValue);
  };

  const UpdateSampleFunction = async (values) => {
    const body = new FormData();

    if (values.action === "2") {
      body.append("rejectionCause", values.rejectionCause);
    }
    body.append("userId", user.userId);
    body.append("containerNumber", values.containerNo);
    body.append("sampleItemId", values.sampleItemId);
    body.append("id", id);
    body.append("blNumber", blNumber);
    body.append("action", values.action);
    body.append("routes", values?.routes);
    body.append("prefrence", values?.prefrence);
    // body.append("labRemarks", values?.labRemarks);

    return await axios.post(`${Config.apiUrl}/update-sample`, body, {
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onUpdateSampleSuccess = (data) => {
    console.log(data);
    if (data?.data?.status === "OK") {
      setIsApproved(true);
      setIsRejected(false);
      toast.success(data?.data?.msg || "Success");
      refetch();
      // navigate("/client-list");
    } else {
      setIsApproved(false);
      setIsRejected(false);
      toast.error(data?.data?.msg);
    }
  };

  const onUpdateSampleError = (data) => {
    setIsApproved(false);
    setIsRejected(false);
    toast.error(data?.response?.data?.msg || "An Error Occurred");
    refetch();
  };

  const { isLoading: UpdateSampleLoading, mutate: updateSampleUser } =
    useMutation(UpdateSampleFunction, {
      onSuccess: onUpdateSampleSuccess,
      onError: onUpdateSampleError,
    });

  const SubmitHandler = (values, index) => {
    if (values.action === "2" && !values.rejectionCause) {
      toast.error("Please write the rejection cause.");
      return;
    }
    // const updatedValues = {
    //   containers: rows,
    // };

    const container = fieldValue[index];
    const routes = container?.routes || "N/A";
    const prefrence = container?.prefrence || "N/A";


    updateSampleUser({ ...values, routes, prefrence });

    // updateSampleUser(values, values.action, index, updatedValues);
  };

  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();

    body.append("labRemarks", values.labRemarks);

    body.append("id", values?.id);

    return await axios.post(`${Config.apiUrl}/upload-labRemark`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      refetch();
      // getClientListMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );
  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };
  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Update Sample Details</Title>
              <Underline />
            </div>
          </div>
          {editUser && (
            <LabRemarkCertificate
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={containerList}
              selectedData={selectedData}
            // sampleItemId={container.sampleItemId} 
            />
          )}
          {(productionLoading || isRefetching) && <Loading />}

          {!productionLoading && !isRefetching && (
            <Wrapper>
              <InputGroup>
                <Label htmlFor="billNumber">Bill Number</Label>
                <Value>{InitialValues.BillNumber || "N/A"}</Value>
              </InputGroup>
              {/* <CombineInputGridGroup>
                <InputGroup>
                  <Label htmlFor="serialNumber">Serial Number</Label>
                  <Value>{InitialValues.serialNumber || "N/A"}</Value>
                </InputGroup>
               
              </CombineInputGridGroup> */}

              {containerList &&
                containerList.map((container, index) => (


                  <div
                    key={index}
                    className="border border-gray-100 px-5 pb-6 pt-3 bg-gray-50 rounded-md w-full overflow-hidden break-words"
                  >


                    <Formik
                      initialValues={{
                        ...InitialValues,
                        containerNo: container.containerNo,
                        sampleItemId: container.sampleItemId,

                      }}
                      // onSubmit={SubmitHandler}
                      // onSubmit={(values) => SubmitHandler(values, container)}
                      onSubmit={(values) => SubmitHandler(values, index)}
                    >
                      {({ values, handleChange }) => (
                        <Form>
                          <InputGroup className="mb-4">
                            <Label htmlFor="containerNo">
                              Container Number
                            </Label>
                            <Value>{container.containerNo || "N/A"}</Value>
                          </InputGroup>

                          {/* <div className="grid md:grid-cols-2 gap-4 mb-4">
                            
                          </div> */}

                          <CombineInputThreeGroup>
                            <InputGroup>
                              <Label htmlFor="samplingTime">
                                Time Sampled/Submitted
                              </Label>
                              <Value>
                                {moment(container.samplingTime).format(
                                  "DD-MM-YYYY HH:mm"
                                ) || "N/A"}
                              </Value>
                            </InputGroup>

                            <InputGroup>
                              <Label htmlFor="samplingCondition">
                                Sample(s) Condition/Temp.
                              </Label>
                              <Value>
                                {container.samplingCondition || "N/A"}
                              </Value>
                            </InputGroup>
                            <InputGroup>
                              <Label htmlFor="productInspected">
                                Product Inspected
                              </Label>
                              <Value>
                                {container.productInspected || "N/A"}
                              </Value>
                            </InputGroup>
                          </CombineInputThreeGroup>

                          <CombineInputThreeGroup className="mt-4">
                            <InputGroup>
                              <Label htmlFor="isSizeAdequate">
                                Sample Size: Adequate?
                              </Label>
                              <Value>
                                {mapIsSizeAdequate(container.isSizeAdequate) ||
                                  "N/A"}
                              </Value>
                            </InputGroup>
                            <InputGroup>
                              <Label htmlFor="count">No. of Samples</Label>
                              <Value>{container.count || "N/A"}</Value>
                            </InputGroup>
                            <InputGroup>
                              <Label htmlFor="samplingLocation">
                                Sampling Location
                              </Label>
                              <Value>
                                {container.samplingLocation || "N/A"}
                              </Value>
                            </InputGroup>
                          </CombineInputThreeGroup>

                          <div className="grid md:grid-cols-3 gap-4 mt-4">
                            <div className="">
                              <InputGroup>
                                <Label htmlFor="specialMark">
                                  Special Marks Required
                                </Label>
                                <Value>{container.specialMark || "N/A"}</Value>
                              </InputGroup>
                            </div>
                            <InputGroup>
                              <Label htmlFor={`routes`}>Routes</Label>
                              {container.status ===
                                Config.sampleStatus.PENDING &&
                                user.userType != Config.userType.ADMIN && (
                                  <FieldWrapper>
                                    <Field
                                      type="text"
                                      name={`routes`}
                                      id={`routes`}
                                      autoComplete="off"
                                      className="truncate disabled:bg-gray-200"
                                      // value={container.routes}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "routes",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FieldWrapper>
                                )}
                              {container.status !==
                                Config.sampleStatus.PENDING && (
                                  <Value>{container.routes || "N/A"}</Value>
                                )}
                            </InputGroup>
                            <InputGroup>
                              <Label htmlFor={`prefrence`}>Preference</Label>
                              {container.status ===
                                Config.sampleStatus.PENDING &&
                                user.userType != Config.userType.ADMIN && (
                                  <FieldWrapper>
                                    <Field
                                      type="text"
                                      name={`prefrence`}
                                      id={`prefrence`}
                                      autoComplete="off"
                                      className="truncate"
                                      // value={container.prefrence}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "prefrence",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FieldWrapper>
                                )}
                              {container.status !==
                                Config.sampleStatus.PENDING && (
                                  <Value>{container.prefrence || "N/A"}</Value>
                                )}
                            </InputGroup>
                          </div>


                          {showRejectionCause &&
                            rejectionContainerIndex === index &&
                            container.status ===
                            Config.sampleStatus.PENDING && (
                              <InputGroup className="mt-4">
                                <Label htmlFor="rejectionCause">
                                  Rejection Cause
                                </Label>
                                <FieldWrapper>
                                  <Field
                                    component="textarea"
                                    row="10"
                                    type="textarea"
                                    name="rejectionCause"
                                    id="rejectionCause"
                                    placeholder="Reason of rejection"
                                    autoComplete="off"
                                    // value={values.rejectionCause}
                                    onChange={handleChange}
                                    required
                                  />
                                </FieldWrapper>
                              </InputGroup>
                            )}


                          <div className="flex justify-end items-center mt-4">



                            {container.status ===
                              Config.sampleStatus.APPROVED && (
                                <div className="flex ">
                                  <FcApproval className="h-7 w-7 mr-1 " />
                                  <p className="mt-0.5 text-[#8BC34A] text-md">
                                    Approved
                                  </p>
                                  {container.certificate && (
                                    <a
                                      href={container?.certificate}
                                      target="_blank"
                                    >
                                      <span className="text-gray-500 flex flex-row mt-1.5 ml-4 underline underline-offset-4">
                                        <FaFileDownload className=" mr-1" />
                                        Download Certificate
                                      </span>
                                    </a>
                                  )}
                                </div>
                              )}
                            {!UpdateSampleLoading &&
                              !isRejected &&
                              container.status ===
                              Config.sampleStatus.REJECTED && (
                                <div className="flex mt-4">
                                  <TiDelete className="h-7 w-7 ml-1 text-rose-500" />
                                  <p className="mt-0.5 text-rose-500 text-md ">
                                    Rejected
                                  </p>
                                  {container.certificate && (
                                    <a
                                      href={container?.certificate}
                                      target="_blank"
                                    >
                                      <span className="text-gray-500  flex flex-row mt-1.5 ml-4 underline underline-offset-4">
                                        <FaFileDownload className=" mr-1" />
                                        Download Certificate
                                      </span>
                                    </a>
                                  )}
                                </div>
                              )}
                            {!UpdateSampleLoading &&
                              container.status ===
                              Config.sampleStatus.PENDING &&
                              user.userType == Config.userType.ADMIN && (
                                <div className="flex mt-4 ">
                                  <IoIosTimer className="h-7 w-7 ml-1 text-yellow-500" />
                                  <p className="mt-0.5 text-yellow-500 text-md ">
                                    Pending
                                  </p>
                                </div>
                              )}

                            {container.labResult && (
                              <a
                                href={container?.labResult}
                                target="_blank"
                              >
                                <span className="text-gray-500 flex flex-row mt-1.5 ml-4 underline underline-offset-4">
                                  <FaFileDownload className=" mr-1" />
                                  Download Lab Result
                                </span>
                              </a>
                            )}
                          </div>
                          <BtnWrapper>
                            {!container.labResult && (
                              <SubmitBtn className="!bg-gray-500 hover:!bg-gray-600 mr-3" type="button" onClick={() => {
                                console.log("Container Object:", container.sampleItemId);
                                setEditUser(true); // Set editUser state to true
                                setSelectedData(container.sampleItemId);;
                              }}><span className="flex"><MdUploadFile className="mr-1 w-5 h-5" /> Upload Lab Result</span></SubmitBtn>)}
                            {container.status === Config.sampleStatus.PENDING &&
                              user.userType != Config.userType.ADMIN &&
                              (
                                <BtnWrapper className="!mt-0">

                                  {!showRejectionCause[index] &&
                                    container.status !=
                                    Config.sampleStatus.APPROVED && (
                                      <>
                                        <SubmitBtn
                                          type="button"
                                          onClick={() =>
                                            SubmitHandler(
                                              { ...values, action: "3" },
                                              index
                                            )
                                          }
                                          className="mr-3"
                                          disabled={
                                            UpdateSampleLoading ||
                                            container.status ===
                                            Config.sampleStatus.APPROVED
                                          }
                                        >
                                          {UpdateSampleLoading && (
                                            <Loading
                                              color="white"
                                              width={20}
                                              height={20}
                                              noPadding={true}
                                            />
                                          )}
                                          {/* {!UpdateSampleLoading && isApproved && (
                                        <IoIosCheckmark className="h-7 w-7" />
                                      )} */}

                                          {!UpdateSampleLoading &&
                                            container.status !==
                                            Config.sampleStatus.APPROVED &&
                                            t("Approve")}
                                        </SubmitBtn>

                                        {container.status !==
                                          Config.sampleStatus.APPROVED && (
                                            <SubmitBtn
                                              className="!bg-rose-500 hover:!bg-rose-600"
                                              type="button"
                                              onClick={() => {
                                                setShowRejectionCause((prev) => {
                                                  const newState = [...prev];
                                                  newState[index] = true;
                                                  return newState;
                                                });
                                                setRejectionContainerIndex(index);
                                              }}
                                            >
                                              {isRejected && <TiDelete />}
                                              {!isRejected && t("Reject")}
                                            </SubmitBtn>
                                          )}
                                      </>
                                    )}
                                  {showRejectionCause[index] && (
                                    <SubmitBtn
                                      type="button"
                                      onClick={() =>
                                        SubmitHandler(
                                          { ...values, action: "2" },
                                          index
                                        )
                                      }
                                      disabled={UpdateSampleLoading}
                                    >
                                      {UpdateSampleLoading && (
                                        <Loading
                                          color="white"
                                          width={20}
                                          height={20}
                                          noPadding={true}
                                        />
                                      )}
                                      {!UpdateSampleLoading && t("Submit")}
                                    </SubmitBtn>
                                  )}
                                </BtnWrapper>
                              )}
                          </BtnWrapper>
                        </Form>
                      )}
                    </Formik>
                  </div>
                ))}
              {/* <InputGroup>
                <Label htmlFor="submitBy">Sample Submitted By</Label>
                <Value>{mapSubmittedBy(InitialValues.submitby) || "N/A"}</Value>
              </InputGroup> */}
            </Wrapper>
          )}
        </BoxContainer>
      </Page>
    </>
  );
};

const Box = tw.div`border border-gray-100 py-4 md:py-8 px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl text-gray-800 whitespace-nowrap font-medium`;
const Value = tw.span`text-sm text-gray-500 font-normal`;
const BtnWrapper = tw.div`flex justify-end items-center mt-8`;
const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Label = tw.span`text-md text-gray-500 font-medium`;

const ApprovedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 12l-2-2-4 4-4-4-2 2"></path>
  </svg>
);

const RejectionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 18L18 6M6 6l12 12"></path>
  </svg>
);

const SubmitBtn = tw.button`
text-sm  w-40 h-9 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed  bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md`;

export default UpdateSampleDetails;

import React from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import { Underline } from "../Styles/PageStyles";
import moment from "moment";

const ViewModel = ({ setOpenViewModel, selectedItem }) => {
    const getOperation = (selectedItem) => {
        if (selectedItem.operation == 1) {
            return <>Production</>;
        } else if (selectedItem.operation == 2) {
            return <>Harvesting</>;
        } else if (selectedItem.operation == 3) {
            return <>Marketing</>;
        }
        else if (selectedItem.operation == 4) {
            return <>Stock Management</>;
        } else {
            return <>Transportation</>;
        }
    };
    const getStatus = (selectedItem) => {
        if (selectedItem.status == 1) {
            return <>Processing</>;
        } else if (selectedItem.status == 2) {
            return <>Generated</>;
        } else if (selectedItem.status == 3) {
            return <>Failed</>;
        }
    };

    return (
        <Model width={"w-11/12 max-w-lg"} setOpenModel={setOpenViewModel}>
            <Wrapper>
                <Title>
                    View Details
                    {/* <Underline /> */}
                </Title>

                <SingleItem name={"Report Name"} value={selectedItem?.report_name} />

                <SingleItem
                    name={"Operation"}
                    value={getOperation(selectedItem) || "N/A"}
                />
                <SingleItem name={"Status"} value={getStatus(selectedItem) || "N/A"} />
                <SingleItem
                    name={"Creation Date"}
                    value={moment(selectedItem?.created_at).format("DD-MM-YYYY ")}
                />
                <SingleItem
                    name={"Date Range"}
                    value={
                        moment(selectedItem?.startDate).format("DD/MM/YYYY") +
                        " - " +
                        moment(selectedItem?.endDate).format("DD/MM/YYYY")
                    }
                />
            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value, link, url }) => (
    <div className="flex items-center py-2 border-b border-gray-100 ">
        <Name>{name}</Name> <p className="w-10">:</p>
        {link && (
            <LinkValue href={url} target="_blank" title="Click to open">
                {value}
            </LinkValue>
        )}
        {!link && <Value>{value}</Value>}
    </div>
);

const Wrapper = tw.div`px-4 my-2`;
const Title = tw.h2`text-lg md:text-2xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`word-break text-sm text-gray-800 font-medium max-w-xs `;
const LinkValue = tw.a`text-sm text-blue-500 max-w-xs truncate cursor-pointer truncate`;

export default ViewModel;

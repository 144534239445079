import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import Config from "../../Config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddHarvestingModel = ({
  AddSubAgentLoading,
  postAddUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const [cropTypes, setCropTypes] = useState([]);
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // console.log(data)
  const SubmitHandler = (values) => {

    postAddUser(values);
  };



  const farmersList = data?.map((item) => ({
    value: item.id,
    label: item.name,
    cropTypes: item.farmerCrop.map((crop) => ({
      value: crop.cropType,
      label: crop.cropName,
    })),


  }));


  const handleFarmerChange = (selectedFarmer) => {
    const selectedFarmerData = farmersList.find(
      (farmer) => farmer.value === selectedFarmer.value
    );
    console.log("selectedFarmerData:", selectedFarmerData);
    if (selectedFarmerData) {
      setCropTypes(selectedFarmerData.cropTypes || []);
    }
  };



  return (
    <Model width={`w-11/12 max-w-3xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Harvesting Detail</Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="farmerId">Farmers List</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="farmerId" id="farmerId" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={farmersList}
                          classNamePrefix="select"


                          onChange={(val) => {
                            props.form.setFieldValue("farmerId", val.value);
                            handleFarmerChange(val);
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="crop">Crop Type</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="crop" id="crop" autoComplete="off">
                      {(props) => (

                        <Select
                          className="w-full h-full"
                          required
                          options={cropTypes}
                          isMulti={false}
                          classNamePrefix="select"
                          value={cropTypes.find((crop) => crop.value === props.field.value)}
                          onChange={(val) => {
                            console.log("Selected Crop:", val.value);
                            props.form.setFieldValue("crop", val.value);
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <>




                  <InputGroup>
                    <Label htmlFor="moisture">Moisture</Label>
                    <FieldWrapper className="relative">
                      <Field
                        type="number"
                        name="moisture"
                        id="moisture"
                        autoComplete="off"
                        className="truncate pr-2"
                        required

                      />
                      <PercentageSign>%</PercentageSign>
                    </FieldWrapper>

                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="date">Harvested Date</Label>
                    <FieldWrapper>
                      <Field
                        type="date"
                        name="date"
                        id="date"
                        autoComplete="off"
                        className="truncate"
                        required
                      />

                    </FieldWrapper>
                  </InputGroup>
                </>
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Grade C (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="graded">Quantity Grade D (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                  </>

                )}

              </CombineInputGroup>






            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;
const PercentageSign = styled.span`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
export default AddHarvestingModel;
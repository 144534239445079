import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function RawMaterialBar({ data }) {

    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        if (data) {
            setFilteredData(data?.data?.data);
        }
    }, [data?.data?.data]);

    const labels = filteredData?.totalRawmeterials
        ? filteredData.totalRawmeterials.map((item) => item.name)
        : [];


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //   display: true,
            //   text: 'Crop per Acre',
            // },
        },
    };



    const BarData = {
        labels,
        datasets: [
            {
                label: 'Issued quantity of raw material per crop',
                data: filteredData?.totalRawmeterials
                    ? filteredData.totalRawmeterials.map((item) => item.rawMaterialQuantity)
                    : [],
                backgroundColor: '#8CBE41',
                height: '100%'
            },

        ],
    };


    return <Bar options={options} data={BarData} />;
}

import tw from "tailwind-styled-components";

export const InputGroup = tw.div`w-full flex flex-col space-y-1.5`;

export const CombineInputGroup = tw.div`w-full flex flex-row space-x-4  items-end`;
export const CombineInputGridGroup = tw.div`w-full grid md:grid-cols-2  gap-4 items-end`;
export const CombineInputThreeGroup = tw.div`w-full grid md:grid-cols-3  gap-4 items-end`;
export const CombineInputGridGroups = tw.div`w-full grid md:grid-cols-4  gap-4 items-end`;
export const CombineInputGridGenerate = tw.div`w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6  gap-4 items-end`;
export const FieldWrapper = tw.div`
${(p) => (p.$select ? "h-10" : "pl-2   field-wrapper border border-gray-300")}
 relative  rounded-md  w-full  flex items-center bg-white`;

export const Label = tw.label`text-sm text-gray-500 font-normal`;

export const FormContainer = tw.div` grid max-w-2xl gap-5 md:gap-7 mt-4`;

export const FormContainerVertical = tw.div` grid  gap-5 md:gap-7 mt-4`;

export const SubmitBtn = tw.button`
text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md`;

export const PreviewBtn = tw.button`
text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-cyan-100 hover:bg-cyan-200 text-cyan-700 rounded-md shadow-md`;

export const OtherBtn = tw.button`
  text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-green-100 hover:bg-green-200 text-gray-600 rounded-md shadow-md`;

export const PrevBtn = tw.button`
  text-sm  w-32  md:w-32 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-gray-200 hover:bg-gray-100 text-gray-600 rounded-md shadow-md`;

export const SaveBtn = tw.button`
  text-sm  w-32  md:w-32 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-cyan-100 hover:bg-cyan-200 text-cyan-700 rounded-md shadow-md`;

export const SmallBtn = tw.button`
  whitespace-nowrap text-xs px-4 py-2 sm:px-6 sm:py-2.5 rounded-md bg-white-400  cursor-pointer hover:bg-gray-200 shadow border transition duration-200 text-gray-600 sm:text-sm`;

export const CancelBtn = tw.button`
  text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-gray-100 hover:bg-gray-200 text-gray-600 rounded-md shadow-md`;

export const ErrorBadge = tw.div` text-red-600 cursor-default border border-red-200 w-24 h-7 font-sans  text-xs  flex justify-center items-center rounded-full bg-red-100`;
const greenBadge = tw.div`bg-green-100 text-green-600 border border-green-200 cursor-default w-24 h-7 font-sans  text-xs  flex justify-center items-center rounded-full `;
export const PendingBadge = tw.div`bg-yellow-100 text-yellow-600 border border-yellow-200 cursor-default w-24 h-7 font-sans  text-xs  flex justify-center items-center rounded-full `;
export const LiveBadge = greenBadge;
export const Btn = tw.button`grid text-custom-green border border-gray-300 place-items-center bg-custom-gray  w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
export const ReadyBadge = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded-full`;
export const CollectedBadge = tw.div`bg-cyan-100 text-cyan-700 text-xs border-cyan-300 border w-24 h-7 flex items-center justify-center rounded-full`;
export const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded-full`;
export const InActiveBadge = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded-full`;
export const RoleBadge = tw.div`bg-gray-100 text-gray-700 text-xs border-gray-300 border w-32 h-7 flex items-center justify-center rounded-full`;
import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGridGroup,
} from "../Styles/InputStyles";
import {
    FieldCustomWrapperDoc
} from "../Styles/PageStyles";
import DropZone from "../DropZonePdf";
import Model from "../Model";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
const LabRemarkCertificate = ({
    editAddUser,
    editEntryLoading,
    setEditUser,
    selectedData,
    sampleItemId,
    data,
}) => {

    const user = useSelector((state) => state.UserReducer.user);

    console.log(selectedData)
    const InitialValues = {


        labRemarks: "",
        id: selectedData,


    };
    console.log(InitialValues)

    // const SubmitHandler = (values) => editAddUser(values);
    const SubmitHandler = (values) => {



        editAddUser(values);
    };

    return (
        <Model width={`w-11/12 max-w-xl`} setOpenModel={setEditUser}>
            <Title>Lab Remark Report</Title>

            {editEntryLoading && <Loading />}

            {!editEntryLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>



                            <InputGroup>
                                <Label htmlFor="labRemarks">Lab Remark</Label>
                                <FieldCustomWrapperDoc $select={true}>
                                    <Field
                                        name="labRemarks"
                                        type="text"
                                        id="labRemarks"
                                        autoComplete="off"
                                    // required
                                    >
                                        {(props) => (
                                            <DropZone
                                                fields={props.field}
                                                setFieldValue={props.form.setFieldValue}
                                                componentFor="labRemarks"
                                                acceptFiles="application/pdf"
                                                File={props.field.value ? [props.field.value] : null} />
                                        )}
                                    </Field>
                                </FieldCustomWrapperDoc>
                                <p className="text-xs text-gray-500 mt-2 ">
                                    Max size: 10MB
                                </p>
                            </InputGroup>


                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Submit</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default LabRemarkCertificate;

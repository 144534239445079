import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "./Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "./Model";
import Images from "../Images";
import { toast } from "react-toastify";
import { AiFillMessage } from "react-icons/ai";
import { IoMdCall } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Config from "../Config";

const DownloadModel = ({
  SingleEntryLoading,
  postSingleEntry,
  setOpenUploadModel,
  setOpenDownloadModel,
  data,
  selectedItem
}) => {
  const InitialValues = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
  };
  //console.log(selectedItem);
  const { t, i18n } = useTranslation();
  const downloadFirstLaw = () => {
    window.open(Config.domain + "download/SAMPULI_YA_KATIBA_ZA_MICHEZO.pdf", "_blank");
  };

  const downloadSecondLaw = () => {
    window.open(Config.domain + "download/KANUNI_ZA_TAASISI_ZA_MICHEZO.pdf", "_blank");
  };

  const downloadOtherForms = () => {
    window.open(Config.domain + "download/FORM_TO_CHANGE_SECTION_ARTICLES_OF_CONSTITUTION.pdf", "_blank");
  };


  //console.log(data)
  return (
    <Model width={`w-11/12 max-w-2xl `} setOpenModel={setOpenDownloadModel}>
      <Title>{t("Download_Samples")}</Title>

      <Wrapper>
        <SingleCard
            isActive = {0}
            title="Sampuli ya Katiba Za Michezo (pdf download)"
            bg="bg-orange-500 text-white "
            icon={
              <img
                src={Images.Create}
                alt="icon"
                className="w-12 sm:w-16"
              />
            }
            callBack={downloadFirstLaw}
        />
        <SingleCard
            isActive = {0}
            title="Kanuni za Taasisi Za Michezo (pdf download)"
            bg="bg-cyan-700 text-white "
            icon={
              <img
                src={Images.Create}
                alt="icon"
                className="w-12 sm:w-16"
              />
            }
            callBack={downloadSecondLaw}
        />
        <SingleCard
            isActive = {0}
            // title="Forms for Application to change sections/articles of the constitution"
            title="Fomu za Maombi ya kubadilisha sehemu/makala ya katiba"
            bg="bg-teal-500 text-white "
            icon={
              <img
                src={Images.Create}
                alt="icon"
                className="w-12 sm:w-16"
              />
            }
            callBack={downloadOtherForms}
        />
        {/* <SingleCard
            isActive = {0}
            title="Change of Address"
            bg="bg-green-400 text-white "
            icon={
              <img
                src={Images.Create}
                alt="icon"
                className="w-12 sm:w-16"
              />
            }
            link={"/change-address"}
        /> */}
      </Wrapper>
    </Model>
  );
};


const SingleCard = ({ isActive, title, bg, callBack }) => {
  
  return (
    <>
      <Link to="" onClick={callBack}>
      <SingleCardWrapper $bg={bg}>
        {/* {icon} */}
        <p className="text-sm sm:text-base">{title}</p>
      </SingleCardWrapper>
      </Link>
    
   
    </>
  );
};

const Wrapper = tw.div`
 mt-10 mb-6 px-1 flex flex-col sm:grid sm:grid-cols-2 justify-between space-y-5  sm:space-y-0 sm:gap-6`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;

const Box = tw.div`flex sm:flex-col items-center space-x-2 sm:space-x-0 sm:space-y-1   rounded-md  py-4  sm:py-5 w-full justify-center  cursor-pointer`;
const BoxText = tw.p`text-base font-medium text-white`;

const SingleCardWrapper = tw.div`
${(p) => p.$bg}
p-4  h-22 w-22 rounded-lg  shadow-lg border border-gray-100  transition duration-200 flex flex-col justify-center space-y-5 items-center  hover:scale-95 transform hover:shadow-sm text-center`;
export default DownloadModel;

import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import DropZone from "../Transportation/Dropzone"
import Model from "../Model";
import { useSelector } from "react-redux";
import moment from "moment";
const EditTransportationModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const user = useSelector((state) => state.UserReducer.user);
  const InitialValues = {
    number: selectedData?.plateNumber,
    gradea: selectedData?.totalQuantityloadedGradeA,
    gradeb: selectedData?.totalQuantityloadedGradeA,
    gradec: selectedData?.totalQuantityloadedGradeC,
    graded: selectedData?.totalQuantityloadedGradeD,

    type: selectedData?.type,
    capacity: selectedData?.capacity,
    id: selectedData?.id,

    issuedate: moment(selectedData?.issueDate).format("YYYY-MM-DD"),
    cropType: selectedData?.cropType,
    cropName: selectedData?.cropName,
    documents: "",
  };




  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-3xl`} setOpenModel={setEditUser}>
      <Title>Edit Transportation </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="cropType" >Crop Name</Label>
                  <FieldWrapper>
                    <Field name="cropName" id="cropType" type="text" autoComplete="off" required disabled />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issuedate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issuedate"
                      id="issuedate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="number">Vehicle Number</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="number"
                      id="number"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="type">Vehicle Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="type"
                      id="type"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="capacity"
                      id="capacity"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              {/* <InputGroup>
                <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    name="capacity"
                    id="capacity"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup> */}
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Grade C(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="graded">Quantity Grade D(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                  </>)}

              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="documents">Delivery Note</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="documents"
                    type="text"
                    id="documents"
                    autoComplete="off"

                    required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="transportation"
                        acceptFiles="application/pdf"
                        File={props.field.value ? [props.field.value] : null}
                      />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                <p className="text-xs text-gray-500 mt-2 ">
                  PDF max size: 10MB
                </p>
              </InputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
const FieldCustomWrapperDoc = tw.div`
${(p) => (p.$select ? "" : "pl-2 field-wrapper ")}
relative  rounded  my-4 bg-gray-100   text-gray-800 items-center w-full`;
export default EditTransportationModel;

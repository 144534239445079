import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Images from "../../Images";
import Loading from "../../Components/Loading";
import Table from "../../Components/Request/Table";
import Navbar from "../../Components/Navbar";

import { toast } from "react-toastify";
import moment from "moment";

import {
    BoxContainer,
    Underline,
    Page,
} from "../../Components/Styles/PageStyles";
import {
    FieldWrapper,
    InputGroup,
    Label,
} from "../../Components/Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NotFoundModel from "../../Components/NotFoundModel";

import { useSelector } from "react-redux";

import { MdAdd } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";


import ViewDetailModel from "../../Components/Request/ViewDetailModel";

const Request = () => {
    const user = useSelector((state) => state.UserReducer.user);

    const [openUploadModel, setOpenUploadModel] = useState(false);

    const [viewDetail, setViewDetail] = useState(false);
    const [generatCertificate, setGeneratCertificate] = useState(false);
    const [selectedData, setSelectedData] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [editUser, setEditUser] = useState(false);
    const [filter, setFilter] = useState("name");
    const [startDate, setStartDate] = useState(
        moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const initialValues = {
        name: "",
        filterVal: "",
        startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
    };

    const fetchFunction = async () => {
        const body = new FormData();
        body.append("startDate", startDate);
        body.append("endDate", endDate);
        body.append("filterType", filter);
        body.append("filterValue", filterValue);
        return await axios.post(`${Config.apiUrl}/get-request-list`, body, {
            headers: {
                authorization: "Bearer" + " " + user.token,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        });
    };

    const getClientListSuccess = (res) => {
        if (res.data.status == "NOK" || res.data.data == false) error = true;
    };

    const getClientListError = (res) => { };

    const {
        isLoading: isLoading,
        mutate: getClientListMutate,
        error,
        data,
        isRefetching,
    } = useMutation(fetchFunction, {
        onSuccess: getClientListSuccess,
        onError: getClientListError,
    });

    useEffect(() => {
        getClientListMutate();
    }, []);

    const submitHandler = (values) => {
        const newValues = {
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD"),
        };
        setStartDate(newValues.startDate);
        setEndDate(newValues.endDate);
        setFilterValue(values.filterVal);
        getClientListMutate();
    };
    console.log(data?.data?.data?.clients);
    //------- Edit Single Entry -------
    const EditFieldAgentFunction = async (item) => {
        const { blNumber, id } = item;
        const body = new FormData();


        body.append("blNumber", blNumber);
        body.append("id", id);
        body.append("type", 2);

        return await axios.post(`${Config.apiUrl}/update-request-status`, body, {
            headers: {
                authorization: "Bearer" + " " + user.token,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        });
    };

    const onEditEntrySuccess = (data) => {
        if (data?.data?.status == "OK") {
            toast.success(data?.data?.msg || "Success");
            // refetch();
            getClientListMutate();
        } else {
            toast.error(data?.data?.msg || "An Error Occured");
        }
        setSelectedData("");
        setEditUser(false);
    };

    const onEditEntryError = (data) => {
        setEditUser(false);
        setSelectedData("");
        toast.error(data?.response?.data?.msg);
    };

    const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
        EditFieldAgentFunction,
        {
            onSuccess: onEditEntrySuccess,
            onError: onEditEntryError,
        }
    );


    return (
        <>
            <Navbar />

            <Page>
                <BoxContainer>
                    <div className="flex w-full justify-between items-center">
                        <div>
                            <Title>Request List</Title>
                            <Underline />
                        </div>

                    </div>
                    <div className="flex w-full justify-between items-center">
                        <Filters
                            submitHandler={submitHandler}
                            initialValues={initialValues}
                            InitialLoading={isLoading}
                            setFilter={setFilter}
                            filter={filter}
                            user={user}
                            t={t}
                        />
                    </div>


                    {viewDetail && (
                        <ViewDetailModel
                            setViewDetail={setViewDetail}
                            selectedData={selectedData}
                        />
                    )}
                    {!error && !isLoading && data && (
                        <TableWrapper>
                            <Table
                                ApiData={error ? [] : data?.data?.data?.clients}
                                setSelectedData={setSelectedData}
                                selectedData={selectedData}
                                setViewDetail={setViewDetail}
                                setEditUser={setEditUser}
                                editAddUser={editAddUser}
                                editEntryLoading={editEntryLoading}

                            />
                        </TableWrapper>
                    )}

                    {(isLoading || isRefetching) && <Loading />}
                    {(error || data?.data?.data?.clients == 0) && !isLoading && (
                        <NotFoundModel />
                    )}
                </BoxContainer>
            </Page>
        </>
    );
};

const Filters = ({
    initialValues,
    submitHandler,
    InitialLoading,
    t,
    filter,
    setFilter,
}) => {
    return (
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
            {(formikProps) => (
                <>
                    <Form>
                        <FilterContainer>
                            <InputGroup className="max-w-xs">
                                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                                <FieldWrapper className="h-10">
                                    <img
                                        src={Images.Calender}
                                        alt="Calender icon"
                                        className="w-3.5"
                                    />
                                    <Field
                                        type="date"
                                        name="startDate"
                                        id="startDate"
                                        class="ml-1"
                                        max={moment().format("YYYY-MM-DD")}
                                    />
                                </FieldWrapper>
                            </InputGroup>

                            <InputGroup className="max-w-xs">
                                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                                <FieldWrapper className="h-10">
                                    <img
                                        src={Images.Calender}
                                        alt="Calender icon"
                                        className="w-3.5"
                                    />
                                    <Field
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        class="ml-1"
                                        min={formikProps.values.startDate}
                                        max={moment().format("YYYY-MM-DD")}
                                    />
                                </FieldWrapper>
                            </InputGroup>

                            <ApplyBtn disabled={InitialLoading} type="submit">
                                {t("Date_filter")}
                            </ApplyBtn>
                            <InputGroup
                                className="max-w-xs lg:ml-20"
                                style={{ flexBasis: "content" }}
                            >
                                <Label htmlFor="filter">{t("Search")}</Label>

                                <Select
                                    autoComplete="off"
                                    className="max-w-xs md:w-36 pl-2 bg-transparent text-sm p-0 border-none"
                                    style={{ height: "40px" }}
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e.target.value);
                                    }}
                                >
                                    <MenuItem value="name">Name</MenuItem>
                                    {/* <MenuItem value="mobile">Mobile</MenuItem> */}
                                    <MenuItem value="blNumber">B/L Number</MenuItem>
                                </Select>
                            </InputGroup>
                            <InputGroup className="max-w-xs">
                                <Label htmlFor="filterVal">{t("Value")}</Label>
                                <FieldWrapper className="h-10">
                                    <Field
                                        type="text"
                                        name="filterVal"
                                        id="filterVal"
                                        class="ml-1"
                                    />
                                </FieldWrapper>
                            </InputGroup>

                            <ApplyBtn disabled={InitialLoading} type="submit">
                                {t("Search")}
                            </ApplyBtn>
                        </FilterContainer>
                    </Form>
                </>
            )}
        </Formik>
    );
};


const TableWrapper = tw.h1`my-10 border bg-white   rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

const ApplyBtn = tw.button`${(p) =>
    p.$active
        ? "bg-gray-100"
        : ""} border whitespace-nowrap w-28 md:w-40 !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  h-10 text-sm 
     `;
const FilterContainer = tw.div`
grid grid-cols-3 w-full gap-4 md:gap-4 lg:flex  items-end mt-12 md:mt-8`;

export default Request;

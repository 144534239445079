import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { Field, Formik, Form, FieldArray } from "formik";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Navbar from "../../Components/Navbar";
import {
  Page,
  BoxContainer,
  BoxTitle,
  Underline,
} from "../../Components/Styles/PageStyles";
import {
  SubmitBtn,
  PreviewBtn,
  OtherBtn,
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loading";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
// import Select from "react-select";
import Select from "@mui/material/Select";
import { HiUpload } from "react-icons/hi";
import { MdAdd, MdDelete, MdKeyboardArrowLeft } from "react-icons/md";

import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { Update_User } from "../../Redux/actions";
import * as Yup from "yup";

const GenerateReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useParams();

  const user = useSelector((state) => state?.UserReducer?.user);

  // -------------   Generate Report  -------------
  const generateFunction = async (values) => {
    const body = new FormData();
    body.append("startDate", values.startDate);
    body.append("endDate", values.endDate);
    body.append("type", values.type);

    return await axios.post(`${Config.apiUrl}/generate-report`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const generateSuccess = (res) => {
    toast.success(res?.data?.msg || "Success");
    navigate("/view-report");
  };

  const generateError = (err) => {
    toast.error(err?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: generateLoading, mutate: generateMutate } = useMutation(
    generateFunction,
    {
      onSuccess: generateSuccess,
      onError: generateError,
    }
  );

  const InitialValue = {
    name: "",
    type: type == "sms" ? 1 : 1,
    startDate: "",
    endDate: "",
  };

  const SubmitHandler = (values) => {
    if (values.endDate < values.startDate) {
      return toast.error("End date must be after start date");
    }
    if (!values.type) {
      return toast.error("Please select the report type");
    }

    generateMutate(values);
  };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <BoxTitle onClick={() => navigate(-1)}>
            <p>Generate Report</p>
          </BoxTitle>
          <Underline />

          <Formik
            initialValues={InitialValue}
            onSubmit={SubmitHandler}
            // validationSchema={validationSchema}
          >
            {(formikProps) => (
              <>
                <Form>
                  <FormContainer>
                    {/* <InputGroup>
              <Label htmlFor="name">Report Name</Label>
              <FieldWrapper>
                <Field
                  name="name"
                  type="text"
                  id="name"
                  autoComplete="off"
                  required
                />
              </FieldWrapper>
            </InputGroup> */}
                    <InputGroup>
                      <Label htmlFor="type">Select Report Type</Label>
                      <FieldWrapper $select={true}>
                        <Field
                          required
                          name="type"
                          id="type"
                          autoComplete="off"
                        >
                          {(props) => (
                            <Select
                              required
                              sx={{
                                paddingLeft: "0px",
                              }}
                              {...props.field}
                              className="w-full h-full pl-2 bg-transparent text-sm p-0  border-none"
                              autoComplete="none"
                            >
                              <MenuItem value="0" disabled>
                                Select Type
                              </MenuItem>
                              <MenuItem value="1">All Containers</MenuItem>

                              <MenuItem value="2">
                                Dispatched Containers
                              </MenuItem>

                              <MenuItem value="3">
                                Conformity & Non Conformity Certificates
                              </MenuItem>
                            </Select>
                          )}
                        </Field>
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="startDate">Start Date</Label>
                      <FieldWrapper>
                        <Field
                          name="startDate"
                          type="date"
                          id="startDate"
                          autoComplete="off"
                          max={moment().format("YYYY-MM-DD")}
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="endDate">End Date</Label>
                      <FieldWrapper>
                        <Field
                          name="endDate"
                          type="date"
                          id="endDate"
                          autoComplete="off"
                          // min={formikProps.values.startDate}
                          max={moment().format("YYYY-MM-DD")}
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </FormContainer>

                  <div className="mt-8">
                    <SubmitBtn type="submit" disabled={generateLoading}>
                      {generateLoading && (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      )}
                      {!generateLoading && "Generate"}
                    </SubmitBtn>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </BoxContainer>
      </Page>
    </>
  );
};

const ButtonWrapper = tw.div`flex items-center space-x-3 h-full `;
const Button = tw.button` 
${(p) =>
  p.$remove
    ? " bg-rose-500 hover:bg-rose-600"
    : " bg-teal-500 hover:bg-teal-600"}
text-white w-28 flex items-center space-x-1 justify-center h-8  text-xs whitespace-nowrap rounded`;

export const FormContainer = tw.div` grid max-w-xl  gap-5 md:gap-7 mt-4 md:mt-8 `;

const TotalPrice = tw.div`px-6 py-3 rounded-md text-white bg-orange-600 text-sm `;

export default GenerateReport;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../../Config";
const ViewBlNUmberList = ({ setViewDetail, selectedData }) => {

    const user = useSelector((state) => state.UserReducer.user);

    return (
        <Model width={`w-11/12 max-w-2xl`} setOpenModel={setViewDetail}>
            <Wrapper>
                <Title><MdViewHeadline className="mt-1" />View Details</Title>
                <SingleItem name={"Company Name"} value={selectedData?.companyName} />
                <SingleItem name={"Client Name"} value={selectedData?.name} />
                <SingleItem name={"Client Mobile"} value={selectedData?.mobile == 0 ? "N/A" : (selectedData?.mobile || "N/A")} />
                {/* <SingleItem name={"Client Mobile"} value={selectedData?.mobile || "N/A"} /> */}
                <SingleItem name={"Client Email"} value={selectedData?.email || "N/A"} />

                <SingleItem name={"Client Address"} value={selectedData?.address} />
                <SingleItem name={"B/L Number"} value={selectedData?.blNumber} />


                {selectedData?.ladingBillPath &&
                    <SingleItem
                        name={"Bill of Lading"}
                        value={
                            <LinkValue>
                                <a href={selectedData?.ladingBillPath} target="_blank">
                                    Click to View
                                </a>
                            </LinkValue> || "N/A"
                        }
                    />}
                {selectedData?.payReciptPath &&
                    <SingleItem
                        name={"Payment Receipt"}
                        value={
                            <LinkValue>
                                <a href={selectedData?.payReciptPath} target="_blank">
                                    Click to View
                                </a>
                            </LinkValue> || "N/A"
                        }
                    />}
                {selectedData?.invoicePath &&
                    <SingleItem
                        name={"Invoice"}
                        value={
                            <LinkValue>
                                <a href={selectedData?.invoicePath} target="_blank">
                                    Click to View
                                </a>
                            </LinkValue> || "N/A"
                        }
                    />}

                <SingleItem name={"Creation Date"} value={moment(selectedData?.created_at).format("DD-MM-YYYY hh:mm A")} />
            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value }) => (
    <div className="flex items-center py-2 border-b border-gray-100">
        <Name>{name}</Name> <p>:</p>
        {<Value>{value}</Value>}
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`word-break  text-sm text-gray-800 font-medium w-80 px-6 `;
const LinkValue = tw.a`text-sm text-blue-500 max-w-xs truncate cursor-pointer`;
export default ViewBlNUmberList;

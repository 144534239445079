import React, { useState, useEffect } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "../../Components/Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MdAdd } from "react-icons/md";
import TableBlNumber from "../../Components/Search/TableBlNumber";

import NotFoundModel from "../../Components/NotFoundModel";
import EditClientModel from "../../Components/DocumentCollectionUser/EditClientModel";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputGridGroup,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  FieldCustomWrapperDoc,
  Page,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import DropZone from "../../Components/DropZonePdf";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ViewBlNUmberList from "../../Components/Search/ViewBlNumberList";
const BlNumberList = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [editStatus, setEditStatus] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [isAddMoreAvailable, setIsAddMoreAvailable] = useState(true);
  const [error, setError] = useState(null);
  const EditProduct = async () => {
    try {
      const response = await axios.get(`${Config.apiUrl}/get-blnumbers/${id}`, {
        headers: {
          Authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data,
    refetch,
    isRefetching,
    isLoading: queryLoading,
    error: queryError,
  } = useQuery("get-production", EditProduct, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error);
      setProductionLoading(false);
    },
  });

  // console.log("Production Loading:", productionLoading);
  // console.log("Query Loading:", queryLoading);
  // console.log("Data:", data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  console.log(data?.data[0]?.companyName);

  //------- Edit Single Entry -------
  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();

    body.append("ladingBill", values.ladingBill);
    body.append("paymentReceipt", values.paymentReceipt);
    body.append("invoice", values.invoice);
    body.append("blNumber", selectedData?.blNumber);
    body.append("id", selectedData?.id);

    return await axios.post(`${Config.apiUrl}/update-client`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      refetch();
      // getClientListMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  return (
    <>
      <Navbar />

      <Page>
        <BoxContainer>
          {viewDetail && (
            <ViewBlNUmberList
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}
          {editUser && (
            <EditClientModel
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {!productionLoading && data && (
            <>
              <div className="flex w-full justify-between items-center">
                <div>
                  <Title>{data?.data[0]?.companyName}</Title>
                  <Underline />
                </div>
              </div>
              <TableWrapper>
                <TableBlNumber
                  ApiData={data?.data || []}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                  setEditUser={setEditUser}
                  setEditStatus={setEditStatus}
                  setViewDetail={setViewDetail}
                />
              </TableWrapper>
            </>
          )}

          {(productionLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data == 0) && !productionLoading && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Page>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  t,
  filter,
  setFilter,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">{t("Start_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">{t("End_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Date_filter")}
          </ApplyBtn>
          <InputGroup
            className="max-w-xs lg:ml-20"
            style={{ flexBasis: "content" }}
          >
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="max-w-xs md:w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <MenuItem value="name">By Name</MenuItem>
              <MenuItem value="mobile">By Mobile</MenuItem>
            </Select>
          </InputGroup>
          <InputGroup className="max-w-xs">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field type="text" name="filterVal" id="filterVal" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = (setAddProd) => {
  return (
    <UploadWrapper>
      <Link
        to={{
          pathname: `/add-client`,
        }}
      >
        <Button type="button" onClick={() => setAddProd(true)}>
          <MdAdd className="text-white w-5 h-5 " /> <p>Add Client</p>
        </Button>
      </Link>
    </UploadWrapper>
  );
};

const TextWrapper = tw.div`h-full w-full  flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const TableWrapper = tw.h1`my-10 border bg-white   rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;
const Underline = tw.div`w-20 mx-auto h-0.5 bg-gray-700 mt-1.5 rounded-full `;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap w-28 md:w-40 !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  h-10 text-sm 
       `;
const FilterContainer = tw.div`
  grid grid-cols-3 w-full gap-4 md:gap-4 lg:flex  items-end mt-12 md:mt-8`;
const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

export default BlNumberList;

import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import Loading from "react-loading";
import Config from "../../Config";
import { FaFileDownload, FaPlus, FaFileExport } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import {
    MdOutlineDescription,
    MdDelete,
    MdModeEdit,
    MdPassword,
    MdSecurityUpdateWarning,
    MdViewCompactAlt,
} from "react-icons/md";
import {
    CollectedBadge,
    PendingBadge,
    ReadyBadge,
    SuccessBadge,
    InActiveBadge,
} from "../Styles/InputStyles";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";

const Table = ({
    ApiData,
    setSelectedData,
    setViewDetail,
    setEditUser,
    editAddUser,
    editEntryLoading,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [loadingId, setLoadingId] = useState(null);
    console.log(editEntryLoading)
    const user = useSelector((state) => state.UserReducer.user);
    const [data, setData] = useState([
        {
            deviceName: "",
            extractionTime: "",
            processingTime: "",
            drops: "",
            action: "",
        },
    ]);
    useEffect(() => {
        console.log("editEntryLoading changed:", editEntryLoading);
    }, [editEntryLoading]);
    const viewDetail = (item) => {
        setViewDetail(true);
        setSelectedData(item);
    };

    const getStatus = (status) => {
        if (status == Config.clientStatus.PENDING) {
            return <PendingBadge>Pending</PendingBadge>;
        } else if (status == Config.clientStatus.COLLECTED) {
            return <CollectedBadge>Collected</CollectedBadge>;
        } else if (status == Config.clientStatus.READY) {
            return <ReadyBadge>Ready</ReadyBadge>;
        } else if (status == Config.clientStatus.REJECTED) {
            return <InActiveBadge>Rejected</InActiveBadge>;
        } else if (status == Config.clientStatus.APPROVED) {
            return <SuccessBadge>Approved</SuccessBadge>;
        }
    };




    const DocumnetBtns = (item) => {
        return (
            <ActionBtnWrapper>
                <Button onClick={() => viewDetail(item)}>
                    Click to View
                </Button>

            </ActionBtnWrapper>
        );
    };
    // const handleApproveClick = (item) => {
    //     setLoadingId(item.id); // Set the loading ID to the current item's ID
    //     editAddUser(item).finally(() => setLoadingId(null)); // Clear the loading ID after the operation is complete
    // };

    // const ActionBtns = (item) => {
    //     console.log(loadingId === item.id)
    //     return (
    //         <ActionBtnWrapper>

    //             <ApproveButton onClick={() => handleApproveClick(item)} disabled={loadingId === item.id}>
    //                 {loadingId === item.id ? (
    //                     <Loading
    //                         color="white"
    //                         width={20}
    //                         height={20}
    //                         noPadding={true}
    //                     />
    //                 ) : "Approve"}
    //             </ApproveButton>
    //         </ActionBtnWrapper>
    //     );
    // };

    const ActionBtns = (item, editEntryLoading) => {
        console.log(editEntryLoading)

        return (
            <ActionBtnWrapper>

                <ApproveButton onClick={() => editAddUser(item)} disabled={editEntryLoading}>
                    {editEntryLoading ? (<Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                    />) : "Approve"}
                </ApproveButton>


            </ActionBtnWrapper>
        );
    };

    useEffect(() => {
        if (ApiData) {
            const tempData = ApiData.map((item) => ({
                clientId: item.id,
                companyName: item.companyName,
                name: item.name,
                mobile: item.mobile !== undefined && item.mobile !== null && item.mobile != 0 ? item.mobile : "N/A",


                blNumber: item.blNumber,
                certificatePath: item.certificatePath,

                ladingBillPath: item.ladingBillPath,

                invoicePath11: item.invoicePath,
                payReciptPath: item.payReciptPath,
                status: getStatus(item.status),


                creationDate: moment(item.creationDate).format("DD-MM-YYYY"),

                action: ActionBtns(item, editEntryLoading),
                document: DocumnetBtns(item),
            }));

            setData(tempData);
        }
    }, [ApiData, editEntryLoading]);

    const columns = React.useMemo(
        () => [

            {
                Header: "Client Name",
                accessor: "name",
            },
            {
                Header: "Client Mobile",
                accessor: "mobile",
            },

            {

                Header: "Status",
                accessor: "status",
            },

            {
                Header: "Creation Date",
                accessor: "creationDate",
            },
            {
                Header: "B/L Number",
                accessor: "blNumber",
            },
            {
                Header: "Document",
                accessor: "document",
            },


            {
                Header: "Action",
                accessor: "action",
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.Arrow}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </CustomTable>

            <PaginationWrapper>
                <div className="px-2">
                    Page{" "}
                    <em>
                        {pageIndex + 1} of {pageOptions.length}
                    </em>
                </div>

                <div className="flex gap-1">
                    <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
                    </PrevBtn>
                    <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                        <img
                            src={Images.Arrow}
                            alt="arrow"
                            className="w-2 rotate-180 opacity-75"
                        />
                    </NextBtn>
                </div>
            </PaginationWrapper>
        </>
    );
};

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;
const Button = tw.button`text-blue-500`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ApproveButton = tw.button`bg-green-500 rounded-full text-white py-2 px-4 items-center`
export default Table;

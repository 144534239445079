import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";

import Images from "../../Images";
import { toast } from "react-toastify";

const SingleEntry = ({
    SingleEntryLoading,
    AddSubAgentLoading,
    postSingleEntry,
    postUser,
    setOpenUploadModel,
    setAddUser,
    searchInputNumber,
    searchInputName,

}) => {
    const [uploadFile, setUploadFile] = useState("");

    const InitialValues = {
        companyName: searchInputName,
        name: "",
        mobile: searchInputNumber,
        email: "",
        address: "",
    };
    const SubmitHandler = (values) => {
        // const isMobileValid = /^[0-9]{9,15}$/.test(values.mobile);

        // if (!isMobileValid) {
        //     toast.error("Mobile number must be between 9 and 15 digits.");
        //     return;
        // }

        postUser({ ...values });
    };


    return (
        <Model width={`w-11/12 max-w-xl `} setOpenModel={setAddUser}>
            <Title>Add Client</Title>

            {AddSubAgentLoading && <Loading />}

            {!AddSubAgentLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>
                            <CombineInputGroup>
                                <InputGroup>
                                    <Label htmlFor="companyName">Company Name</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="companyName"
                                            id="companyName"
                                            placeholder="Company name"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                                {/* } */}
                                <InputGroup>
                                    <Label htmlFor="name">Name</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Name"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                            </CombineInputGroup>
                            <CombineInputGroup>
                                <InputGroup>
                                    <Label htmlFor="mobile">Mobile Number</Label>
                                    <FieldWrapper >
                                        <Field
                                            type="number"
                                            name="mobile"
                                            id="mobile"
                                            placeholder="Mobile Number"
                                            autoComplete="off"
                                            className="truncate"
                                        // required
                                        // readOnly
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <Label htmlFor="email">Email</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            autoComplete="off"
                                            className="truncate"
                                        // required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                            </CombineInputGroup>
                            <InputGroup>
                                <Label htmlFor="address">Address</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder="Address"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>
                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Add</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;

export default SingleEntry;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import { useSelector } from "react-redux";
const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const user = useSelector((state) => state.UserReducer.user);
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        <SingleItem name={"Product Name"} value={selectedData?.productName} />

        <SingleItem name={"Quantity Received Grade A(kg)"} value={selectedData?.qtyRecivedGradeA} /><SingleItem name={"Quantity Received Grade B(kg)"} value={selectedData?.qtyRecivedGradeB} />

        {user.companyName == "Kimolo Super Rice Ltd" && (<><SingleItem name={"Quantity Received Grade C(kg)"} value={selectedData?.qtyRecivedGradeC} /><SingleItem name={"Quantity Received Grade D(kg)"} value={selectedData?.qtyRecivedGradeD} /></>)}

        <SingleItem name={"Receiving Date"} value={moment(selectedData?.receivingDate).format("DD-MM-YYYY hh:mm A")} />

        <SingleItem name={"Quantity Issued Grade A(kg)"} value={selectedData?.quantityissuedGradeA} /><SingleItem name={"Quantity Issued Grade B(kg)"} value={selectedData?.quantityissuedGradeB} />
        {user.companyName == "Kimolo Super Rice Ltd" && (
          <><SingleItem name={"Quantity Issued Grade C(kg)"} value={selectedData?.quantityissuedGradeC} /><SingleItem name={"Quantity Issued Grade D(kg)"} value={selectedData?.quantityissuedGradeD} /></>)}

        <SingleItem name={"Balance Quantity Grade A(kg)"} value={selectedData?.balance} /><SingleItem name={"Balance Quantity Grade B(kg)"} value={selectedData?.balanceb} />
        {user.companyName == "Kimolo Super Rice Ltd" && (
          <><SingleItem name={"Balance Quantity Grade C(kg)"} value={selectedData?.balancec} /><SingleItem name={"Balance Quantity Grade D(kg)"} value={selectedData?.balanced} /></>)}
        <SingleItem name={"Issued Date"} value={moment(selectedData?.dispachDate).format("DD-MM-YYYY hh:mm A")} />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewDetailModel;

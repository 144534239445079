import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import Card from "../Components/Card/RegisterCard";
import {
  FieldWrapper,
  Label,
  InputGroup,
  CombineInputGroup,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const InitialValue = {
    name: "",
    phone: "",
    password: "",
  };

  //------- Send Register Request -------
  const RegisterPostFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.firstName + " " + values.lastName);
    body.append("phone", values.phone);
    body.append("password", values.password);
    return await axios.post(`${Config.apiUrl}/create-user`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    RegisterPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    postRegister({ ...values });
    navigate("/get-otp", { state: { phone: values.phone } });
  };

  const handleLogin = () => {};

  return (
    <Login
      style={{
        backgroundImage: `url(${Images.LoginImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      <Card>
        <TextWrapperComp
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          InitialValue={InitialValue}
          SubmitHandler={SubmitHandler}
          t={t}
        />
      </Card>
    </Login>
  );
};

const TextWrapperComp = ({
  password,
  setPassword,
  IsValidPassword,
  handleLogin,
  InitialValue,
  SubmitHandler,
  showOTP,
  setShowOTP,
  t,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <div className="flex space-x-6"></div>
        <Title>{t("Register Now")}</Title>
        {showOTP && (
          <>
            <OtpSubTitle>{t("sent_otp")}</OtpSubTitle>
            <DefaultOtpTitle>Or Enter default OTP 1111</DefaultOtpTitle>
          </>
        )}
        <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
          {(formikProps) => (
            <>
              <Form>
                <FormContainer>
                  {!showOTP && (
                    <>
                      <CombineInputGroup>
                        <InputGroup>
                          <Label htmlFor="firstName">{t("First Name")}</Label>
                          <FieldWrapper>
                            <Field
                              name="firstName"
                              id="firstName"
                              inputMode="text"
                              autoComplete="off"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="lastName">{t("Last Name")}</Label>
                          <FieldWrapper>
                            <Field
                              name="lastName"
                              id="lastName"
                              inputMode="text"
                              autoComplete="off"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputGroup>

                      <InputGroup>
                        <Label htmlFor="phone">{t("Mobile Number")}</Label>
                        <FieldWrapper>
                          <Field
                            name="phone"
                            type="number"
                            id="phone"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <div className="-mt-5">
                        <Labels>
                          {t(
                            "This mobile number is your account's unique ID and will be used for future logins."
                          )}
                        </Labels>
                      </div>

                      <InputGroup className="-mt-2">
                        <Label htmlFor="password">{t("Password")}</Label>
                        <FieldWrapper>
                          <Field
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="repassword">
                          {t("Re-Enter Password")}
                        </Label>
                        <FieldWrapper>
                          <Field
                            name="repassword"
                            type="password"
                            id="password"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </>
                  )}

                  {showOTP && (
                    <InputGroup className="w-full">
                      <Label htmlFor="otp">{t("e_opt")}</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="otp"
                          id="otp"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  )}
                </FormContainer>
                <div className="mt-8">
                  <SubmitBtn type="submit">{t("Get_OTP")}</SubmitBtn>
                </div>
              </Form>
            </>
          )}
        </Formik>

        <Link className="my-5 " to="/">
          <p className="text-sm text-gray-500 pb-5 ">
            {t("have_account")}{" "}
            <span className="text-gray-500 hover:text-cyan-600 font-medium">
              {t("login")}
            </span>
          </p>
        </Link>
      </Center>
    </Container>
  </TextWrapper>
);

const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section`h-screen  flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col justify-center  items-start   `;
const TextWrapper = tw.div`h-full relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl font-black tracking-wider `;
const OtpSubTitle = tw.h3`mt-2 sm:mt-2 tracking-wider  text-gray-900 text-sm `;
const DefaultOtpTitle = tw.h3`mt-1 sm:mt-1 tracking-wider  text-gray-700 text-sm `;
const Center = tw.div`h-full w-11/12  py-10 mb-5  flex flex-col   `;
const SubmitBtn = tw.button`
text-sm -mt-0  md:w-80 w-56 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-black hover:bg-gray-900 text-white rounded-md shadow-md`;
const Labels = tw.label`text-xs text-gray-500 font-normal`;
export default RegisterPage;

import React from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import moment from "moment";

const ViewModel = ({ setOpenViewModel, selectedItem, searchType }) => {
    return (
        <Model width={"w-11/12 max-w-2xl"} setOpenModel={setOpenViewModel}>
            <Wrapper>
                <Title>View Details</Title>
                <SingleItem name={"Company name"} value={selectedItem?.companyName} />
                <SingleItem name={"Name"} value={selectedItem?.name} />
                <SingleItem name={"Mobile"} value={selectedItem?.mobile == 0 ? "N/A" : (selectedItem?.mobile || "N/A")} />

                {/* <SingleItem name={"Mobile"} value={selectedItem?.mobile || "N/A"} /> */}
                <SingleItem name={"Email"} value={selectedItem?.email || "N/A"} />
                <SingleItem name={"Address"} value={selectedItem?.address} />

            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value, link, url }) => (
    <div className="flex items-center py-2 border-b border-gray-100 ">
        <Name>{name}</Name> <p className="w-10">:</p>
        {link && (
            <LinkValue href={value} target="_blank" title="Click to open">
                {value}
            </LinkValue>
        )}
        {!link && <Value>{value}</Value>}
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`text-sm text-gray-800 font-medium max-w-xs break-words `;
const LinkValue = tw.a`text-sm text-blue-500 max-w-xs break-words cursor-pointer`;

export default ViewModel;


import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function TransportDoughnut({ data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);
  console.log(data?.data?.data)
  const labels = filteredData?.totalCropsTranspotation
    ? filteredData.totalCropsTranspotation.map((item) => item.name)
    : [];
  console.log(labels)
  const pieData = {
    labels: labels,
    datasets: [
      {
        label: 'Transport quantity per crop',
        data: filteredData?.totalCropsTranspotation
          ? filteredData.totalCropsTranspotation.map((item) => item.cropQuantity)
          : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={pieData} />;
}

import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import Loading from "../../Components/Loading";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  InputGroup,
  FieldWrapper,
  // Label,
  SubmitBtn,
  CombineInputGridGroup,
  CombineInputGridGroups,
  CombineInputGridGenerate,
  CombineInputGroup,
  CombineInputThreeGroup,
} from "../../Components/Styles/InputStyles";
import {
  Underline,
  BoxContainer,
  FieldCustomWrapperDoc,
  Page,
} from "../../Components/Styles/PageStyles";
import Select from "react-select";
import Navbar from "../../Components/Navbar";
import moment from "moment";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

const ViewSampleDetail = ({}) => {
  const { t, i18n } = useTranslation();
  const [descriptions, setDescriptions] = useState([""]);
  const [units, setUnits] = useState([""]);
  const [routes, setRoutes] = useState([""]);
  const [prefrences, setPrefrences] = useState([""]);
  const navigate = useNavigate();
  const { id, blNumber } = useParams();

  const user = useSelector((state) => state.UserReducer.user);

  // const [isProductionFetched, setIsProductionFetched] = useState(false);

  // const EditProduct = async () =>
  //   await axios.get(`${Config.apiUrl}/get-details/${id}/${blNumber}`, {
  //     headers: {
  //       Authorization: "Bearer" + " " + user.token,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //   });

  // const { data, isLoading: productionLoading } = useQuery(
  //   "get-production",

  //   EditProduct,
  //   {
  //     enabled: !isProductionFetched,
  //   }
  // );

  // useEffect(() => {
  //   if (data?.data && !isProductionFetched) {
  //     setIsProductionFetched(true);
  //   }
  // }, [isProductionFetched, data]);

  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [error, setError] = useState(null);
  const EditProduct = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/get-details/${id}/${blNumber}`,
        {
          headers: {
            Authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      // Handle errors here
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data,
    isLoading: queryLoading,
    error: queryError,
  } = useQuery("get-production", EditProduct, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error); // Set the error state
      setProductionLoading(false); // Set loading to false in case of an error
    },
  });

  console.log("Production Loading:", productionLoading);
  console.log("Query Loading:", queryLoading);
  console.log("Data:", data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  // const SubmitHandler = (values) => generateCertificate(values);
  const InitialValues = {
    serialNumber: data?.data?.sample?.serialNumber,
    submitby: data?.data?.sample?.submittedBy,
    BillNumber: data?.data?.sample?.billNumber,
    billLeading: data?.data?.sample?.blNumber,
  };

  const mapSubmittedBy = (value) => {
    if (value === "1") {
      return "Client";
    } else if (value === "2") {
      return "Staff";
    } else {
      return "";
    }
  };

  // Mapping function for isSizeAdequate
  const mapIsSizeAdequate = (value) => {
    if (value === "1") {
      return "Yes";
    } else if (value === "2") {
      return "No";
    } else {
      return "";
    }
  };

  const containerList = data?.data?.sampleItems?.map((item) => ({
    // routes: item.routes,
    // prefrence: item.prefrence,
    containerNo: item.containerNumber,
    samplingTime: item.samplingTime,
    samplingLocation: item.samplingLocation,
    samplingCondition: item.sampleCondition,
    specialMark: item.specialMarksRequired,
    count: item.count,
    productInspected: item.productInspected,
    isSizeAdequate: item.isSizeAdequate,
  }));
  console.log(containerList);
  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>View Sample Details</Title>
                <Underline />
              </div>
            </div>
            {/* <TextWrapper> */}
            {productionLoading && <Loading />}

            {/* {error && !productionLoading && <NotFoundModel />} */}
            {!productionLoading && (
              <Wrapper>
                {/* {containerList &&
                containerList.map((container, index) => ( */}
                <InputGroup>
                  <Label htmlFor="billNumber">Bill Number</Label>
                  <Value>{InitialValues.BillNumber || "N/A"}</Value>
                </InputGroup>
                {/* <CombineInputGridGroup>
                  <InputGroup>
                  <Label htmlFor="serialNumber">Serial Number</Label>
                  <Value>{InitialValues.serialNumber || "N/A"}</Value>
                </InputGroup>
                 
                </CombineInputGridGroup> */}
                {/* ))} */}
                {containerList &&
                  containerList.map((container, index) => (
                    <div className="border border-gray-100 px-5 pb-6 pt-2  bg-gray-50 rounded-md w-full overflow-hidden break-words">
                      <InputGroup className="mb-4 ">
                        <Label htmlFor="containerNo">Container Number</Label>
                        <Value>{container.containerNo || "N/A"}</Value>
                      </InputGroup>
                      {/* <CombineInputGroup className="mb-4">
                        <InputGroup>
                          <Label htmlFor="routes">Routes</Label>
                          <Value>{container.routes || "N/A"}</Value>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="prefrence">Standard Prefrence</Label>
                          <Value>{container.prefrence || "N/A"}</Value>
                        </InputGroup>
                      </CombineInputGroup> */}
                      <CombineInputThreeGroup>
                        <InputGroup>
                          <Label htmlFor="samplingTime">
                            Time Sampled/Submitted
                          </Label>

                          <Value>
                            {moment(container.samplingTime).format(
                              "DD-MM-YYYY HH:mm"
                            ) || "N/A"}
                          </Value>
                        </InputGroup>
                        {/* </CombineInputGridGroup>

              <CombineInputGridGroup> */}
                        <InputGroup>
                          <Label htmlFor="samplingCondition">
                            Sample(s) Condition/Temp.
                          </Label>
                          <Value>{container.samplingCondition || "N/A"}</Value>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="productInspected">
                            Product Inspected
                          </Label>
                          <Value>{container.productInspected || "N/A"}</Value>
                        </InputGroup>
                        {/* </CombineInputGridGroup>
              <CombineInputGridGroup> */}
                        <InputGroup>
                          <Label htmlFor="isSizeAdequate">
                            Sample Size: Adequate?
                          </Label>
                          <Value>
                            {mapIsSizeAdequate(container.isSizeAdequate) ||
                              "N/A"}
                          </Value>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="count">No. of Samples</Label>
                          <Value>{container.count || "N/A"}</Value>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="samplingLocation">
                            Sampling Location
                          </Label>
                          <Value>{container.samplingLocation || "N/A"}</Value>
                        </InputGroup>
                      </CombineInputThreeGroup>
                      {/* </CombineInputGridGroup>
              <CombineInputGridGroup> */}
                      {/* <CombineInputGridGroup className="mt-4"> */}
                      {/* <div className="flex flex-col md:flex-row md:gap-28 gap-4  mt-4"> */}
                      <div className="grid md:grid-cols-3 gap-4 mt-4">
                        <div className="col-span-3 ">
                          <InputGroup>
                            <Label htmlFor="specialMark">
                              Special Marks Required
                            </Label>
                            <Value>{container.specialMark || "N/A"}</Value>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* <InputGroup>
                  <Label htmlFor="submitBy">Sample Submitted By</Label>
                  <Value>
                    {mapSubmittedBy(InitialValues.submitby) || "N/A"}
                  </Value>
                </InputGroup> */}
              </Wrapper>
            )}
          </Box>
        </BoxContainer>
      </Page>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full  flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
  text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
  grid  gap-6 my-6 px-1`;
const Label = tw.span`text-md text-gray-500 font-medium`;
const Value = tw.span`text-sm text-gray-500 font-normal`;
export default ViewSampleDetail;

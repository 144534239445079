import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import Config from "../../Config";
import { FaFileDownload, FaPlus, FaFileExport } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import {
    MdOutlineDescription,
    MdDelete,
    MdModeEdit,
    MdPassword,
    MdSecurityUpdateWarning,
    MdViewCompactAlt,
} from "react-icons/md";
import {
    CollectedBadge,
    PendingBadge,
    ReadyBadge,
    SuccessBadge,
    InActiveBadge,
} from "../Styles/InputStyles";

import { useSelector } from "react-redux";

const Table = ({
    ApiData,
    setSelectedData,
    setEditUser,
    setViewDetail,
    setAddSample,
    setEditSample,
    setUpdateSample,
    setDescription,
    setGeneratCertificate,
    setEditStatus,
}) => {
    const user = useSelector((state) => state.UserReducer.user);
    const [data, setData] = useState([
        {
            deviceName: "",
            extractionTime: "",
            processingTime: "",
            drops: "",
            action: "",
        },
    ]);


    const getStatus = (status) => {
        if (status == 1) {
            return <InActiveBadge>Processing</InActiveBadge>
        } else {
            return <SuccessBadge>Uploaded</SuccessBadge>

        }
    }



    useEffect(() => {
        if (ApiData) {
            const tempData = ApiData.map((item) => ({
                sno: item.id,
                name: item.name,
                action: <Download download href={item.file}>Download CSV</Download>,
                creationDate: moment(item.created_at).format("DD-MM-YYYY"),
                status: getStatus(item.status),


            }));

            setData(tempData);
        }
    }, [ApiData]);

    const columns = React.useMemo(
        () => [


            {
                Header: "Name",
                accessor: "name",
            },

            {
                Header: "Uploaded Date",
                accessor: "creationDate",
            },
            {
                Header: "Status",
                accessor: "status",
            },

            {
                Header: "Action",
                accessor: "action",
            },

        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.Arrow}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </CustomTable>

            <PaginationWrapper>
                <div className="px-2">
                    Page{" "}
                    <em>
                        {pageIndex + 1} of {pageOptions.length}
                    </em>
                </div>

                <div className="flex gap-1">
                    <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
                    </PrevBtn>
                    <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                        <img
                            src={Images.Arrow}
                            alt="arrow"
                            className="w-2 rotate-180 opacity-75"
                        />
                    </NextBtn>
                </div>
            </PaginationWrapper>
        </>
    );
};

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;


const Download = tw.a`!w-32 h-8 flex justify-center items-center bg-green-600 text-white text-sm rounded w-auto mr-auto`
export default Table;

import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";

import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";

import { FaFileExport } from "react-icons/fa";

import { IoArrowBackSharp } from "react-icons/io5";
import {

    LiveBadge,
    PendingBadge,
    ErrorBadge,
} from "../Styles/InputStyles";

const AdminTable = ({ ApiData, setOpenViewModel, setSelectedItem }) => {
    const [data, setData] = useState([]);

    const getStatus = (status) => {
        if (status == 1) {
            return <PendingBadge>Processing</PendingBadge>;
        } else if (status == 2) {
            return <LiveBadge>Generated</LiveBadge>;
        } else {
            return <ErrorBadge>Failed</ErrorBadge>;
        }
    };
    const getOperation = (operation) => {
        if (operation == 1) {
            return <p>Production</p>;
        } else if (operation == 2) {
            return <p>Harvesting</p>;
        } else if (operation == 3) {
            return <p>Marketing</p>;
        }
        else if (operation == 4) {
            return <p>Stock Management</p>;
        } else {
            return <p>Payment</p>;
        }
    };

    const view = (item) => {
        setOpenViewModel(true);
        setSelectedItem(item);
    };

    const ActionBtns = (item) => {
        return (
            <ActionBtnWrapper>
                <Btn title="View All Details" onClick={() => view(item)}>
                    <HiEye className="text-gray-700" />
                </Btn>
                {item.status == 2 && (
                    <a href={item.file_path} download>
                        <Btn title="Export Report">
                            <FaFileExport />
                        </Btn>
                    </a>
                )}
            </ActionBtnWrapper>
        );
    };

    useEffect(() => {
        if (ApiData) {
            const tempData = ApiData.map((item) => ({
                name: (
                    <p className=" truncate w-40 mr-4" value={item.report_name}>
                        {" "}
                        {item.report_name}
                    </p>
                ),

                operation: getOperation(item.operation),
                status: getStatus(item.status),
                date: moment(item?.created_at).format("DD/MM/YYYY"),
                dateRange:
                    moment(item?.from_date).format("DD/MM/YYYY") +
                    " - " +
                    moment(item?.to_date).format("DD/MM/YYYY"),
                action: ActionBtns(item),
            }));

            setData(tempData);
        }
    }, [ApiData]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Date Range",
                accessor: "dateRange",
            },
            {
                Header: "Report Type",
                accessor: "operation",
            },

            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Date",
                accessor: "date",
            },

            {
                Header: "Action",
                accessor: "action",
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.ArrowWhite}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr
                                {...row.getRowProps()}
                                {...row.getRowProps()}
                            // style={{
                            //   backgroundColor: index % 2 === 0 ? "#f3f4f6" : "#FFFFFF",
                            // }}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")} </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </CustomTable>
            {data.length > 0 && (
                <PaginationWrapper>
                    <div className="px-2 pb-2">
                        Page{" "}
                        <em>
                            {pageIndex + 1} of {pageOptions.length}
                        </em>
                    </div>

                    <div className="flex gap-1 pb-2">
                        <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <IoArrowBackSharp />
                        </PrevBtn>
                        <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                            <IoArrowBackSharp className="rotate-180" />
                        </NextBtn>
                    </div>
                </PaginationWrapper>
            )}
        </>
    );
};

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;


export default AdminTable;

import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";

import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputGridGroup,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
// import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
const EditRawMaterial = () => {
  const [cropName, setCropName] = useState("");
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const EditProduct = async () =>
    await axios.get(`${Config.apiUrl}/get-rawmaterial/${id}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const { data, isLoading: productionLoading } = useQuery(
    "get-production",

    EditProduct,
    {
      enabled: !isProductionFetched,
    }
  );

  console.log(data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  console.log(data?.data?.data?.source);
  //------- Edit Single Entry -------
  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();
    body.append("cropType", values.cropType);
    if (user.companyName == "Kimolo Super Rice Ltd") {
      body.append("gradec", values.gradec);
      body.append("graded", values.graded);

      body.append("balancec", values.balancec);
      body.append("balanced", values.balanced);

      body.append("issuegradec", values.issuegradec);
      body.append("issuegraded", values.issuegraded);
    }
    body.append("gradea", values.gradea);
    body.append("gradeb", values.gradeb);
    body.append("issuegradea", values.issuegradea);
    body.append("issuegradeb", values.issuegradeb);
    body.append("balance", values.balance);
    body.append("balanceb", values.balanceb);

    body.append("source", values.source);
    body.append("date", values.date);
    body.append("issueDate", values.issueDate);

    body.append("companyId", user.companyId);
    body.append("id", id);

    return await axios.post(`${Config.apiUrl}/update-rawmaterial`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      navigate("/raw-material");
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
  };

  const onEditEntryError = (data) => {
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  console.log(data?.data);

  const InitialValue = {
    source: data?.data?.data?.source,
    gradea: data?.data?.data?.qtyRecivedGradeA,
    gradeb: data?.data?.data?.qtyRecivedGradeB,
    gradec: data?.data?.data?.qtyRecivedGradeC,
    graded: data?.data?.data?.qtyRecivedGradeD,

    date: moment(data?.data?.data?.receivingDate).format("YYYY-MM-DD"),
    issueDate: moment(data?.data?.data?.issueDate).format("YYYY-MM-DD"),
    issuegradeb: data?.data?.data?.quantityissuedGradeA,
    issuegradea: data?.data?.data?.quantityissuedGradeB,
    issuegradec: data?.data?.data?.quantityissuedGradeC,
    issuegraded: data?.data?.data?.quantityissuedGradeD,

    destination: data?.data?.data?.destination,
    balance: data?.data?.data?.balance,
    balanceb: data?.data?.data?.balanceb,
    balancec: data?.data?.data?.balancec,
    balanced: data?.data?.data?.balanced,

    cropType: data?.data?.data?.cropType,
    cropName: data?.data?.data?.cropName,

    id: id,
  };
  const SubmitHandler = (values) => editAddUser({ ...values });
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Edit Raw Material</Title>
              <Underline />
            </div>
          </div>
          <TextWrapper>
            <Formik
              initialValues={InitialValue}
              onSubmit={SubmitHandler}
              enableReinitialize
            >
              <>
                <Form>
                  <Wrapper>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="cropType">Crop Type</Label>
                        <FieldWrapper>
                          <Field
                            name="cropName"
                            id="cropType"
                            type="text"
                            autoComplete="off"
                            required
                            disabled
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="source">Source</Label>
                        <FieldWrapper>
                          <Field
                            name="source"
                            id="source"
                            type="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <CombineInputGridGroup>
                      <InputGroup>
                        <Label htmlFor="gradea">
                          Quantity Received Grade A(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="gradea"
                            id="gradea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="gradeb">
                          Quantity Received Grade B(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="gradeb"
                            id="gradeb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="gradec">
                              Quantity Received Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="gradec"
                                id="gradec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="graded">
                              Quantity Received Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="graded"
                                id="graded"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputGridGroup>

                    <CombineInputGridGroup>
                      <InputGroup>
                        <Label htmlFor="issuegradea">
                          Quantity Issued Grade A(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegradea"
                            id="issuegradea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="issuegradeb">
                          Quantity Issued Grade B(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegradeb"
                            id="issuegradeb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="issuegradec">
                              Quantity Issued Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="issuegradec"
                                id="issuegradec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="issuegradead">
                              Quantity Issued Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="issuegraded"
                                id="issuegraded"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputGridGroup>

                    <CombineInputGridGroup>
                      <InputGroup>
                        <Label htmlFor="balance">
                          Balance Quantity Grade A (kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balance"
                            id="balance"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="balanceb">
                          Balance Quantity Grade B (kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balanceb"
                            id="balanceb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="balancec">
                              Balance Quantity Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="balancec"
                                id="balancec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="balanced">
                              Balance Quantity Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="balanced"
                                id="balanced"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputGridGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="date">Receiving Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="date"
                            id="date"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="issueDate">Issued Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={editEntryLoading}>
                      {" "}
                      {editEntryLoading ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Update")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              </>
            </Formik>
          </TextWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

export default EditRawMaterial;

import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Navbar from "../../Components/Navbar";
import Loading from "../../Components/Loading";
import Table from "../../Components/ViewReports/Table";
import {
  Page,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { toast } from "react-toastify";
import ViewModel from "../../Components/ViewReports/ViewModel";
import moment from "moment";
import { Field, Form, Formik } from "formik";

const ViewReports = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openViewModel, setOpenViewModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [editModel, setEditModel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const navigate = useNavigate();

  //------- Get Reports -------

  const initialValues = {
    name: "",
    filterVal: "",
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);

    return await axios.post(`${Config.apiUrl}/view-report`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getClientListSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getClientListError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getClientListMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getClientListSuccess,
    onError: getClientListError,
  });
  console.log(data?.data?.data);

  useEffect(() => {
    getClientListMutate();
  }, []);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);

    getClientListMutate();
  };
  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <div>
            <Title>Reports</Title>
            <Underline />
          </div>

          <div className="flex items-end justify-between">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setOpenUploadModel={setOpenUploadModel}
              user={user}
            />
            <Uploader setOpenUploadModel={setOpenUploadModel} />
          </div>

          {openViewModel && (
            <ViewModel
              setOpenViewModel={setOpenViewModel}
              selectedItem={selectedItem}
            />
          )}

          {!isLoading && (
            <TableWrapper>
              <Table
                setOpenViewModel={setOpenViewModel}
                ApiData={error ? [] : data?.data?.data}
                setSelectedItem={setSelectedItem}
              />
            </TableWrapper>
          )}

          {isLoading && <Loading />}
          {data?.data?.length == 0 && !isLoading && <NotFoundModel />}
          {error && !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Page>
    </>
  );
};

const Uploader = () => {
  return (
    <UploadWrapper>
      <Link to="/reports/generate">
        <Button type="button">
          <MdAdd className="text-white w-5 h-5 shrink-0" />{" "}
          <p>Generate Report</p>
        </Button>
      </Link>
    </UploadWrapper>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  setOpenUploadModel,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      {(formikProps) => (
        <>
          {/* <div className="w-full"> */}
          <Form>
            <FilterContainer>
              <InputGroup className="max-w-xs">
                <Label htmlFor="startDate">Start Date:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                    max={moment().format("YYYY-MM-DD")}
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-xs">
                <Label htmlFor="endDate">End Date:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="ml-1"
                    min={formikProps.values.startDate}
                    max={moment().format("YYYY-MM-DD")}
                  />
                </FieldWrapper>
              </InputGroup>

              <ApplyBtn disabled={InitialLoading} type="submit">
                Apply Filter
              </ApplyBtn>
              {/* <Uploader setOpenUploadModel={setOpenUploadModel} /> */}
            </FilterContainer>
          </Form>
          {/* </div> */}
        </>
      )}
    </Formik>
  );
};

const TableWrapper = tw.h1`my-10 border   rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex md:justify-end space-x-4 items-center `;

const Button = tw.button`
 text-white bg-custom-green  flex items-center md:!ml-auto space-x-1 justify-center sm:w-40 w-[8.5rem]   h-10 text-xs  whitespace-nowrap rounded px-6
`;
const FilterContainer = tw.div`
grid grid-cols-3 w-full gap-4 md:gap-4 lg:flex  items-end mt-12 md:mt-8`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap sm:w-40 w-[8.5rem] !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-gray-500  rounded-md px-6  cursor-pointer hover:bg-gray-100  h-10 text-sm 
     `;

export default ViewReports;

import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";

const EditProductionModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  //const deviceData = data.find((item) => item.id === deviceId);
//console.log(selectedData);
  const InitialValues = {
    name:selectedData?.name,
    email: selectedData?.email,
    phone: selectedData?.phone,
    type: selectedData?.type,
    id:selectedData?.id,
    // status: selectedData?.status,
  };

  const SubmitHandler = (values) => editAddUser(values);

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Production</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
            <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="truncate"
                  />
                </FieldWrapper>
              </InputGroup>
              {/* <InputGroup>
                <Label htmlFor="type">User Type</Label>
                <FieldWrapper $select={true}>
                  <Field required name="type" id="type" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        {...props.field}
                      >
                        <MenuItem value="2">Finance</MenuItem>
                        <MenuItem value="3">Registrar</MenuItem>
                        <MenuItem value="4">Ass. Registrar</MenuItem>
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup> */}
              {/* <CombineInputGroup> */}
                <InputGroup>
                  <Label htmlFor="name">Name</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <Field type="hidden" name="id" value={selectedData?.id || ""} />
              {/* <CombineInputGroup> */}
              <InputGroup>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditProductionModel;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import Config from "../../Config";
import { useSelector } from "react-redux";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
    const user = useSelector((state) => state.UserReducer.user);
    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
            <Wrapper>
                <Title><MdViewHeadline className="mt-1" />View Details</Title>

                <SingleItem name={"Farmer Name"} value={selectedData?.farmerName} />
                <SingleItem name={"Moisture"} value={selectedData?.moisture || "NA"} />


                <SingleItem name={"Quantity Grade A (kg)"} value={selectedData?.quantityGradeA} />
                <SingleItem name={"Quantity Grade B (kg)"} value={selectedData?.quantityGradeB} />

                {user.companyName == "Kimolo Super Rice Ltd" && (
                    <>
                        <SingleItem name={"Quantity Grade C (kg)"} value={selectedData?.quantityGradeC} />
                        <SingleItem name={"Quantity Grade D (kg)"} value={selectedData?.quantityGradeD} />
                    </>)}


                <SingleItem name={"Harvest Date"} value={moment(selectedData?.harvestingDate).format("DD-MM-YYYY hh:mm A")} />


            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value }) => (
    <div className="flex items-center py-2 border-b border-gray-100">
        <Name>{name}</Name> <p>:</p>
        {<Value>{value}</Value>}
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-40`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewDetailModel;

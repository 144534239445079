import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGridGroup,
    CombineInputGroup,
} from "../Styles/InputStyles";
import {
    FieldCustomWrapperDoc
} from "../Styles/PageStyles";
import DropZone from "../DropZonePdf";
import Model from "../Model";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
const GenerateCertificateForm = ({
    generateCertificate,
    generateCertificateLoading,
    setGeneratCertificate,
    selectedData,
}) => {
    const [containerNumbers, setContainerNumbers] = useState(['']);

    const addRow = () => {
        setContainerNumbers([...containerNumbers, '']);
    };
    const removeRow = () => {
        if (containerNumbers.length > 1) {
            const updatedContainerNumbers = [...containerNumbers];
            updatedContainerNumbers.pop();
            setContainerNumbers(updatedContainerNumbers);
        }
    };

    const handleInputChange = (index, value) => {
        const updatedContainerNumbers = [...containerNumbers];
        updatedContainerNumbers[index] = value;
        setContainerNumbers(updatedContainerNumbers);
    };

    const user = useSelector((state) => state.UserReducer.user);
    const InitialValues = {


    };
    console.log(InitialValues)

    // const SubmitHandler = (values) => generateCertificate(values);
    const SubmitHandler = (values) => {

        const updatedValues = {
            ...values,
            containerNos: containerNumbers,
        };

        console.log(updatedValues);
        generateCertificate(updatedValues);
    };

    return (
        <Model width={`w-11/12 max-w-xl`} setOpenModel={setGeneratCertificate}>
            <Title>Generate Certificate</Title>

            {generateCertificateLoading && <Loading />}

            {!generateCertificateLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>
                            <CombineInputGroup>
                                <InputGroup>
                                    <Label htmlFor="certificateNo">Certificate No.</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="certificateNo"
                                            id="certificateNo"
                                            placeholder="Container No."
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                                <InputGroup>
                                    <Label htmlFor="referenceNo">Reference No</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="referenceNo"
                                            id="referenceNo"
                                            placeholder="Reference No."
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                            </CombineInputGroup>
                            <CombineInputGroup>
                                <InputGroup>
                                    <Label htmlFor="invoiceNB">Invoice NB</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="text"
                                            name="invoiceNB"
                                            id="invoiceNB"
                                            placeholder="Invoice NB"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>


                                <InputGroup>
                                    <Label htmlFor="date">Invoice Date</Label>
                                    <FieldWrapper>
                                        <Field
                                            type="date"
                                            name="date"
                                            id="date"
                                            placeholder="Invoice Date"
                                            autoComplete="off"
                                            className="truncate"
                                            required
                                        />
                                    </FieldWrapper>
                                </InputGroup>
                            </CombineInputGroup>

                            {/* <InputGroup>
                                <Label htmlFor="containerNo">Container No.</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="containerNo"
                                        id="containerNo"
                                        placeholder="Container No."
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup> */}

                            {containerNumbers.map((containerNumber, index) => (
                                <div key={index} className="input-group">
                                    <InputGroup>
                                        <Label htmlFor={`containerNo${index}`}>Container No.</Label>
                                        <FieldWrapper>
                                            <Field
                                                type="text"
                                                name={`containerNo${index}`}
                                                id={`containerNo${index}`}
                                                placeholder="Container No."
                                                autoComplete="off"
                                                className="truncate"
                                                required
                                                value={containerNumber}
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />
                                        </FieldWrapper>

                                    </InputGroup>
                                </div>
                            ))}
                            <div className="flex -mt-5">
                                <button onClick={addRow} className="text-blue-500 text-sm mr-12">Add Another Row</button>
                                {containerNumbers.length > 1 && (
                                    <button onClick={removeRow} className="text-blue-500 text-sm ">
                                        Remove Row
                                    </button>)}


                            </div>


                            <InputGroup>
                                <Label htmlFor="description">
                                    Description
                                </Label>
                                <FieldWrapper>
                                    <Field
                                        component="textarea"
                                        row="10"
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        autoComplete="off"
                                        required />
                                </FieldWrapper>
                            </InputGroup>

                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Generate</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default GenerateCertificateForm;

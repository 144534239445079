import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User, Save_Games_Data } from "../Redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import Card from "../Components/Card/LoginCard";
import {
  InputGroup,
  FieldWrapper,
  Label,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";
import Loading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedLang, setActiveLang] = useState("eng");

  const InitialValue = {
    email: "",
    password: "",
  };

  //------- Send Login Request -------
  const LoginPostFunction = async (values) => {
    const body = new FormData();
    body.append("email", values.email);
    body.append("password", values.password);
    body.append("device", "1");
    return await axios.post(`${Config.apiUrl}/login`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      const expireAt = moment()
        .add(Config.sessionExpiredTime, "minutes")
        .valueOf();
      const realData = { ...res.data.data, expireAt };
      dispatch(Save_User(realData));
      navigate("/dashboard");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postLogin } = useMutation(
    LoginPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Login Request -------

  const SubmitHandler = (values) => {
    if (!values.email) {
      return toast.error("Please enter Email");
    } else if (!values.password) {
      return toast.error("Please enter password");
    }
    postLogin({ ...values });
    // navigate("/companies");
  };

  const handleLogin = () => {};

  const languageChangeHandler = (value) => {
    setActiveLang(value);
    i18n.changeLanguage(value);
  };
  // linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
  // linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  return (
    <Login
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Images.LoginImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      {/* <LanguageWrapper>
        <LangButton
          $active={selectedLang === "swahili"}
          onClick={() => languageChangeHandler("swahili")}
        >
          Swahili
        </LangButton>
        <LangButton
          $active={selectedLang === "eng"}
          onClick={() => languageChangeHandler("eng")}
        >
          English
        </LangButton>
      </LanguageWrapper> */}
      {/* <div className="flex"> */}
      <TextOverlay>
        {/* <Gradient> */}
        <img src={Images.LOGO1} alt="LOGO" className="mb-4 w-5/12" />
        <OverlayTitle>
          <p>Port Clearance</p>
          <p className="mt-6"> Automation</p>
        </OverlayTitle>
        <OverlayText>
          Our team meticulously examines each shipment to guarantee that it
          meets industry standards and adheres to specified requirements.
        </OverlayText>
        {/* </Gradient> */}
      </TextOverlay>
      <Card>
        <TextWrapperComp
          handleLogin={handleLogin}
          InitialValue={InitialValue}
          SubmitHandler={SubmitHandler}
          isLoadingReg={isLoadingReg}
          t={t}
        />
      </Card>
      {/* </div> */}
    </Login>
  );
};

const TextWrapperComp = ({
  handleLogin,
  InitialValue,
  SubmitHandler,
  t,
  isLoadingReg,
  countryCodes,
  CustomPhoneInput,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <Title>{t("LoginWelcome")}</Title>
        <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
          {(formikProps) => (
            <>
              <Form className="w-full">
                <FormContainer>
                  <InputGroup>
                    <Label htmlFor="email">{t("Email")}</Label>
                    <FieldWrapper>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="password">{t("Password")}</Label>
                    <FieldWrapper>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <ForgotWrapper>
                    <Link to="/forgot-password">
                      {/* <ForgotPassword> */}
                      {t("ForgotPassword")}
                      {/* </ForgotPassword> */}
                    </Link>
                  </ForgotWrapper>
                  <RowWrapper className="-mt-3">
                    <Checkbox
                      icon={<BsCheckLg className="text-cyan-600 w-2 h-2" />}
                      name="my-input"
                      onChange={(value) => {}}
                      borderColor="#6B7280"
                      style={{ cursor: "pointer" }}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: "none",
                        color: "#6B7280",
                        fontSize: ".875rem",
                      }}
                      label={t("rememeber")}
                    />
                  </RowWrapper>
                </FormContainer>
                <div className=" mt-10 flex">
                  <SubmitBtn type="submit" disabled={isLoadingReg}>
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("login")
                    )}
                  </SubmitBtn>
                </div>
                {/* <SubmitBtn type="submit" disabled={runCampaignLoading}>
                    {runCampaignLoading && (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    )}
                    {!runCampaignLoading && "Send"}
                  </SubmitBtn> */}
              </Form>
            </>
          )}
        </Formik>
      </Center>

      {/* <Link className="md:-mt-10 lg:mt-2 mt-2" to="/register">
        <p className="text-sm text-gray-500 -mt-8 ">
          {t("no_account")}{" "}
          <span className="text-gray-500 hover:text-gray-900 font-medium">
            {t("registar_u")}
          </span>
        </p>
      </Link> */}
    </Container>
  </TextWrapper>
);

// const Select = tw.select`h-10 pr-2 border border-gray-300 rounded-l-md`;

// const Field = tw.input`pl-2 h-10 border border-gray-300 rounded-r-md w-full`;
// const Gradient = tw.div`md:w-10/12 lg:text-5xl lg:w-7/12  bg-gradient-to-r from-black to-transparent`;
const TextOverlay = tw.div`hidden lg:block md:absolute md:top-[45%] md:left-[5%]   px-10 transform -translate-y-32 items-center `;
const OverlayTitle = tw.h2` drop-shadow md:text-4xl md:w-10/12 lg:text-[55px] lg:w-7/12 text-white md:leading-10 font-bold `;
const OverlayText = tw.p`text-base pl-2 py-1 mt-2 text-white md:w-10/12 lg:w-7/12 mt-5 leading-relaxed `;
const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
// const Label = tw.label`text-sm text-white font-normal`;
const Login = tw.section`h-screen flex  bg-white justify-center items-center w-full `;
const Container = tw.div`flex  flex-col h-full w-full justify-center items-start   `;
const TextWrapper = tw.div`h-full  relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl text-black font-medium tracking-wider md:-mt-14 -mt-8`;
const Center = tw.div`h-full w-11/12   pt-20 mb-5  flex flex-col   `;
const LoginWrapper = tw.div`flex`;
const PageWrapper = tw.div`text-white text-3xl`;
const LanguageWrapper = tw.div`absolute top-5 right-5 flex space-x-2.5 items-center z-50`;
const RowWrapper = tw.div`flex space-x-2.5 items-center mb-2 justify-between w-full`;
// const ForgotPassword = tw.button`tracking-wider  text-gray-500 text-xs hover:text-gray-900 float-right w-32 md:w-auto`;
const ForgotWrapper = tw.div`-mt-3 flex justify-end tracking-wider  text-gray-600 text-xs hover:text-gray-700 w-auto`;
const LangButton = tw.button`
${(p) =>
  p.$active
    ? "bg-cyan-600 text-white"
    : "hover:bg-gray-300 bg-gray-200 text-gray-800"}
px-4 py-2 text-xs  cursor-pointer  rounded-full `;
const SubmitBtn = tw.button`
text-sm -mt-8  w-80 h-10 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-black hover:bg-black text-white rounded-md shadow-md`;
export default LoginPage;

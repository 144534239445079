import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "@mui/material/Select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";

const AddUserModel = ({
  AddUserLoading,
  postAddUser,
  setOpenUploadModel,

  data,
}) => {

  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {
    //console.log(values.roles);
    const isMobileValid = /^[0-9]{9,15}$/.test(values.mobile);

    if (!isMobileValid) {
      toast.error("Mobile number must be between 9 and 15 digits.");
      return;
    }
    if (values.password.length < 5) {
      toast.error("Password should be more than 5 characters");
      return;
    }
    if (!values.type) {
      toast.error("Please select type for user");
      return;
    }
    postAddUser(values);
  };




  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add User</Title>

      {AddUserLoading && <Loading />}

      {!AddUserLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="name" >Name</Label>
                  <FieldWrapper>
                    <Field name="name" id="name" type="text" placeholder="Name" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="email">Email</Label>
                  <FieldWrapper>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="password">Password</Label>
                  <FieldWrapper>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="mobile">Mobile Number</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="type">Type</Label>
                <FieldWrapper $select={true}>
                  <Field required name="type" id="type" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        {...props.field}
                      >
                        <MenuItem value="1">Document Collector</MenuItem>
                        <MenuItem value="2">Sample Collector</MenuItem>
                        <MenuItem value="3">Quality Assurer</MenuItem>
                        <MenuItem value="4">Inspector</MenuItem>
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddUserModel;

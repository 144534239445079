import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import DropzoneForContact from "../Contacts/DropzoneForContact";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";

const ChangePasswordModal = ({
  setChangePasswordModel,
  changePasswordMutate,
  changePasswordLoading,
}) => {
  const InitialValues = {
    old: "",
    new: "",
    confirm: "",
  };

  const SubmitHandler = (values) => {
    if (changePasswordLoading) return;

    if (values.password.length < 5) {
      return toast.error("Password should be more than 5 characters");
    } else if (values.password !== values.confirmPassword) {
      return toast.error("New & Confirm Password should be same");
    }

    changePasswordMutate({
      password: values?.password,
      confirmPassword: values?.confirmPassword,
    });
  };

  return (
    <Model width={`w-11/12 max-w-xl `} setOpenModel={setChangePasswordModel}>
      <Title>Change Password</Title>

      {changePasswordLoading && <Loading />}

      {!changePasswordLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="password">Password</Label>
                <FieldWrapper>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <FieldWrapper>
                  <Field
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update Password</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-10 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;
const Button = tw.button`text-white bg-teal-500 hover:bg-teal-600 w-52 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

export default ChangePasswordModal;

import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import Loading from "../../Components/Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGridGroup,
  CombineInputGridGroups,
  CombineInputGridGenerate,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import {
  Underline,
  BoxContainer,
  FieldCustomWrapperDoc,
  Page,
} from "../../Components/Styles/PageStyles";
import Select from "react-select";

import Navbar from "../../Components/Navbar";
import moment from "moment";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import CountrySelector from "../../Components/DocumentCollectionUser/CountrySelector";
const GenerateCertificateForm = ({ }) => {
  const [selectedCountry, setSelectedCountry] = useState({
    label: "Somalia",
    value: "SO",
  });
  // console.log(selectedCountry.label);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  const { t, i18n } = useTranslation();
  const [descriptions, setDescriptions] = useState([""]);
  const [units, setUnits] = useState([""]);
  const [routes, setRoutes] = useState([""]);
  const [prefrences, setPrefrences] = useState([""]);
  const navigate = useNavigate();
  const { id, blNumber } = useParams();
  const [rows, setRows] = useState([
    { description: "", unit: "", route: "", preference: "", quantity: "" },
  ]);

  const [selectedGoodProcess, setSelectedGoodProcess] = useState({
    label: "Please Select",
    value: "NA",
  });

  const addRow = () => {
    setRows([
      ...rows,
      {
        description: "",
        unit: "",
        route: "",
        preference: "",
        quantity: "",
        item: "",
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();
      setRows(updatedRows);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedRows = [...rows];
    updatedRows[index][fieldName] = value;
    setRows(updatedRows);
  };

  const user = useSelector((state) => state.UserReducer.user);

  const [isProductionFetched, setIsProductionFetched] = useState(false);

  const EditProduct = async () =>
    await axios.get(
      `${Config.apiUrl}/get-billnumber-Detail/${id}/${blNumber}`,
      {
        headers: {
          Authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );

  const { data, isLoading: productionLoading } = useQuery(
    "get-production",

    EditProduct,
    {
      enabled: !isProductionFetched,
    }
  );

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  // const SubmitHandler = (values) => generateCertificate(values);
  const InitialValues = {
    importer: data?.data?.data?.details?.companyName,
    countryExport: "selectedCountry.label",
    // countryExport: selectedCountry.label || "Somalia",
    importerAddress: data?.data?.data?.details?.address,
    billLeading: data?.data?.data?.details?.blNumber,
    invoiceDate: moment().format("YYYY-MM-DD"),
    description: "",
    remark: "CONTAINERS TO BE RELEASED FOR INSPECTION , NOT FOR EXIT",
    voucher: "",
    exporter: "",
    exporterAddress: "",
    portArrival: "",

  };
  // console.log(InitialValues);
  const AddGenerateFunction = async (values, countryExportCode) => {
    const body = new FormData();

    // body.append("description", values.descriptiondetails);
    // body.append("units", values.units);
    // body.append("routes", values.routes);
    // body.append("prefrences", values.prefrences);
    // body.append("quantity", values.quantities);
    body.append("userId", user.userId);
    body.append("billLeading", values.billLeading);
    body.append("invoiceNo", values.invoiceNo);
    // body.append("containers", JSON.stringify(values.containers));
    body.append("invoiceDate", values.invoiceDate);
    body.append("remark", values.remark);
    body.append("description", values.description);
    body.append("voucher", values.voucher);
    body.append("processOfGoods", selectedGoodProcess.value);
    // body.append("item", "Container");
    // body.append("issuingDate", values.issuingDate);
    body.append("importer", values.importer);
    body.append("importerAddress", values.importerAddress);
    body.append("countryExport", values.countryExport);
    // body.append("countryExport", countryExportCode);
    body.append("exporter", values.exporter);
    body.append("exporterAddress", values.exporterAddress);
    body.append("portArrival", values.portArrival);
    body.append("id", id);

    return await axios.post(`${Config.apiUrl}/generate-certificate`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");
      if (res?.data?.certificate) {
        const certificateUrl = res.data.certificate;
        window.open(certificateUrl, "_blank");
      }
      navigate("/client-list");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: generateCerificate } = useMutation(
    AddGenerateFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    const countryExportCode = selectedCountry ? selectedCountry.value : "";

    const updatedValues = {
      ...values,
      countryExport: selectedCountry ? selectedCountry.label : "",
    };
    console.log("Selected Country:", updatedValues);

    if (selectedGoodProcess.value == "NA") {
      toast.error("Please select the process of goods");
      return;
    }
    generateCerificate(updatedValues, countryExportCode);
  };

  // const SubmitHandler = (values) => {

  //   console.log(rows);
  //   const updatedValues = {
  //     ...values,
  //     containers: rows,
  //   };

  //   generateCerificate(updatedValues);
  // };

  const processOptions = [
    { label: "Lab Test", value: "lab" },
    { label: "Inspection", value: "inspection" },
    { label: "Others", value: "others" },
  ];

  const handleProcessChange = (selectedOption) => {
    setSelectedGoodProcess(selectedOption);
  };

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <Box>
            <div className="flex w-full justify-center items-center">
              <div>
                <Title>Release Certificate</Title>
                <Underline />
              </div>
            </div>
            {/* <TextWrapper> */}
            <Formik
              initialValues={InitialValues}
              onSubmit={SubmitHandler}
              enableReinitialize
            >
              <>
                <Form>
                  <Wrapper>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="billLeading">Bill Leading Number</Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="billLeading"
                            id="billLeading"
                            placeholder="Bill Leading number"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="importer">Importer</Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="importer"
                            id="importer"
                            placeholder="Importer"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="importerAddress">
                          Importer Address
                        </Label>
                        <FieldWrapper className="bg-gray-100">
                          <Field
                            type="text"
                            name="importerAddress"
                            id="importerAddress"
                            placeholder="Importer Address"
                            autoComplete="off"
                            className="truncate"
                            required
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="countryExport">Country of Export</Label>
                        <FieldWrapper className="!pl-0">
                          <CountrySelector onChange={handleCountryChange} />

                          {/* <Field
                            type="text"
                            name="countryExport"
                            id="countryExport"
                            placeholder="Country of Export"
                            autoComplete="off"
                            className="truncate"
                            required
                          /> */}
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporter">Exporter</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="exporter"
                            id="exporter"
                            placeholder="Exporter"
                            autoComplete="off"
                            className="truncate"
                          // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporterAddress">
                          Exporter Address
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="exporterAddress"
                            id="exporterAddress"
                            placeholder="Exporter Address"
                            autoComplete="off"
                            className="truncate"
                          // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="portArrival">Port Arrival</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="portArrival"
                            id="portArrival"
                            placeholder="Port Arrival"
                            autoComplete="off"
                            className="truncate"
                          // required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="invoiceNo">Invoice No</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="invoiceNo"
                            id="invoiceNo"
                            placeholder="Invoice No"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="invoiceDate">Invoice Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="invoiceDate"
                            id="invoiceDate"
                            placeholder="Invoice Date"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="exporterAddress">
                          Process of Goods
                        </Label>
                        <FieldWrapper $select={true}>
                          <Select
                            value={selectedGoodProcess}
                            onChange={handleProcessChange}
                            options={processOptions}
                            placeholder="Select a country"
                            className="w-full"
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="exporter">Receipt Voucher</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="voucher"
                            id="voucher"
                            placeholder=""
                            autoComplete="off"
                            className="truncate"
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="description">Good's Description</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="10"
                          type="textarea"
                          name="description"
                          id="description"
                          autoComplete="off"
                        // required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="remark">Remark</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="10"
                          type="textarea"
                          name="remark"
                          id="remark"
                          autoComplete="off"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={isLoadingReg}>
                      {" "}
                      {isLoadingReg ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Generate")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              </>
            </Formik>
            {/* </TextWrapper> */}
          </Box>
        </BoxContainer>
      </Page>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full  flex justify-center`;
const Box = tw.div`border border-gray-100 py-4 md:py-8  px-4 md:px-20 shadow-md rounded-md`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
  text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
  grid  gap-6 my-6 px-1`;

export default GenerateCertificateForm;

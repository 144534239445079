import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Report/Table";
import SingleEntry from "../../Components/Report/SingleEntry";
import Navbar from "../../Components/Navbar";
import {
  Page,
  BoxTitle,
  Underline,
  BoxContainer,
  Bg,
} from "../../Components/Styles/PageStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";

import { Field, Form, Formik } from "formik";
import ViewModel from "../../Components/Report/ViewModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Report = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const [partner, setPartner] = useState({});
  // assign end
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openViewModel, setOpenViewModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  //------- Get Reports -------
  const getReportsFunction = async (values) => {
    // console.log("data-edit", values);
    const body = new FormData();
    body.append("user_id", values?.user_id);
    body.append("appId", values?.appId);

    return await axios.post(`${Config.apiUrl}/get-reports`, values, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getReportsSuccess = (res) => {};

  const getReportsError = (res) => {};

  const {
    isLoading: getReportsLoading,
    mutate: getReportsMutate,
    error,
    data,
  } = useMutation(getReportsFunction, {
    onSuccess: getReportsSuccess,
    onError: getReportsError,
  });

  useEffect(() => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    getReportsMutate(body);
  }, []);
  console.log(getReportsLoading);
  //------- Add Single Entry -------
  const SingleEntryPostFunction = async (values) =>
    await axios.post(`${Config.apiUrl}/generate-report`, values, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const onSingleEntrySuccess = (res) => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);

    getReportsMutate(body);
    setOpenUploadModel(false);
    toast.success(res?.data?.msg || "Success");
  };

  const onSingleEntryError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: SingleEntryLoading, mutate: postSingleEntry } =
    useMutation(SingleEntryPostFunction, {
      onSuccess: onSingleEntrySuccess,
      onError: onSingleEntryError,
    });

  const CampaignList = data?.data?.campaigns?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const initialValues = {
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const submitHandler = (values) => {
    const body = new FormData();
    body.append("startDate", moment(values.startDate).format("YYYY-MM-DD"));
    body.append("endDate", moment(values.endDate).format("YYYY-MM-DD"));

    setStartDate(startDate);
    setEndDate(endDate);
    getReportsMutate(body);
  };

  return (
    <Bg
      style={{
        backgroundImage: `url(${Images.BeamsBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top left",
        height: "100vh",
      }}
    >
      <Navbar />

      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div>
            <Title>{t("Reports")}</Title>
            <Underline />
          </div>
          <Uploader setOpenUploadModel={setOpenUploadModel} t={t} />
        </div>

        <div className="flex w-full justify-between items-center ">
          <Filters
            submitHandler={submitHandler}
            initialValues={initialValues}
            InitialLoading={getReportsLoading}
            user={user}
            t={t}
          />
        </div>

        {openUploadModel && (
          <SingleEntry
            postSingleEntry={postSingleEntry}
            SingleEntryLoading={SingleEntryLoading}
            partner={data?.data?.partners}
            // partner={partner}
            setPartner={setPartner}
            setOpenUploadModel={setOpenUploadModel}
            CampaignList={CampaignList}
          />
        )}
        {openViewModel && (
          <ViewModel
            selectedItem={selectedItem}
            setOpenViewModel={setOpenViewModel}
          />
        )}

        {!getReportsLoading && (
          <TableWrapper>
            <Table
              ApiData={data?.data?.data || []}
              setOpenViewModel={setOpenViewModel}
              setSelectedItem={setSelectedItem}
              partner={data?.data?.partners}
              setPartner={setPartner}
            />
            {data?.data?.data.length == 0 && !getReportsLoading && (
              <NotFoundModel />
            )}
            {error && !getReportsLoading && <NotFoundModel />}
          </TableWrapper>
        )}

        {getReportsLoading && <Loading />}
      </BoxContainer>
    </Bg>
  );
};

const Uploader = ({ setOpenUploadModel }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>Generate Report</p>
      </Button>
    </UploadWrapper>
  );
};

const Filters = ({ initialValues, submitHandler, InitialLoading, user, t }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">{t("Start_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">{t("End_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Date_filter")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

export const FieldWrappers = tw.div`
${(p) => (p.$select ? "" : "pl-2  field-wrapper border border-gray-300")}
 relative  rounded-md   w-full  flex items-center`;

const TableWrapper = tw.h1`my-10 border bg-white  rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap w-28 md:w-40  !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  h-10 text-sm 
     `;
const FilterContainer = tw.div`
grid grid-cols-3  w-full gap-4 lg:flex   items-end mt-12 md:mt-8`;
export default Report;

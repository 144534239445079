import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";

import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const AddProduction = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { companyId } = useParams();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const GetProduct = async () =>
    await axios.get(`${Config.apiUrl}/get-farmers/${companyId}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const { isLoading, data } = useQuery("get-farmer", GetProduct, {
    enabled: !isDataFetched,
  });
  console.log(data);

  useEffect(() => {
    if (data?.data && !isDataFetched) {
      setIsDataFetched(true);
    }
  }, [isDataFetched, data]);

  const AddProductionFunction = async (values) => {
    const body = new FormData();
    body.append("farmerId", values.farmerId);
    body.append("grade", values.variety);
    body.append("cropType", values.crop);
    body.append("acersPlanted", values.acres);
    body.append("datePlanted", values.date);
    body.append("prodPractice", values.prodPractice);
    body.append("dap", values.dap);
    body.append("urea", values.urea);
    body.append("pest", values.pest);
    body.append("weddingCount", values.weddingCount);
    body.append("companyId", user.companyId);

    return await axios.post(`${Config.apiUrl}/create-production`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");

      navigate("/production-records");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    AddProductionFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    postRegister({ ...values });
  };

  const farmersList = data?.data?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const InitialValue = {
    farmerId: "",
    variety: "",
    crop: "",
    acres: "",
    date: "",
    weddingCount: "",
    urea: "",
    dap: "",
    pest: "",
    prodPractice: "",
    companyId: "",
  };
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Add Production</Title>
              <Underline />
            </div>
          </div>
          <TextWrapper>
            <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
              <>
                <Form>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="farmerId">Farmers List</Label>
                      <FieldWrapper $select={true}>
                        <Field name="farmerId" id="farmerId" autoComplete="off">
                          {(props) => (
                            <Select
                              className="w-full h-full"
                              required
                              options={farmersList}
                              classNamePrefix="select"
                              onChange={(val) => {
                                props.form.setFieldValue("farmerId", val.value);
                              }}
                            />
                          )}
                        </Field>
                      </FieldWrapper>
                    </InputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="crop">Crop Type</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            required
                            name="crop"
                            id="crop"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={cropNames}
                                classNamePrefix="select"
                                onChange={(val) => {
                                  props.form.setFieldValue("crop", val.value);
                                }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="variety">Variety Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="variety"
                            placeholder="Variety Type"
                            id="variety"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="acres">Acres Planted</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="acres"
                            placeholder="acres"
                            id="acres"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="date">Planted Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="date"
                            id="date"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="weddingCount">Number of Weedings</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="weddingCount"
                            id="weddingCount"
                            placeholder="Number of Weeding"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="urea">Urea (bags)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="urea"
                            placeholder="urea"
                            id="urea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="dap">Dap (bags)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="dap"
                            id="dap"
                            placeholder="DAP"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="pest">Pest/disease control</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="pest"
                            id="pest"
                            placeholder="Pest/disease control"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <InputGroup>
                      <Label htmlFor="prodPractice">Production Practices</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="7"
                          type="textarea"
                          name="prodPractice"
                          id="prodPractice"
                          autoComplete="off"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </Wrapper>
                  <UpdateBtn type="submit" disabled={isLoadingReg}>
                    {" "}
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("Add")
                    )}
                  </UpdateBtn>
                </Form>
              </>
            </Formik>
          </TextWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
export default AddProduction;
